import home from "components/templates/home/home";
import missions from "components/templates/missions/missions";
import standard from "components/templates/standard/standard";
import who from "components/templates/who/who";
import article from "components/templates/article/article";
import pageLinks from "components/templates/pageLinks/pageLinks";
import helpDetail from "components/templates/helpDetail/helpDetail";
import process from "components/templates/process/process";
import processes from "components/templates/processes/processes";
import homeProcessSites from "components/templates/homeProcessSites/homeProcessSites";
import knowEverything from "components/templates/knowEverything/knowEverything";
import contact from "components/templates/contact/contact";
import geolocalisation from "components/templates/geolocalisation/geolocalisation";
import help from "components/templates/help/help";
import helpMairie from "components/templates/help/helpMairie";
import glossary from "components/templates/glossary/glossary";
import homeMairie from "components/templates/homeMairie/homeMairie";
import floatingImage from "components/templates/floatingImage/floatingImage";
import comedec from "components/templates/comedec/comedec";
import news from "components/templates/news/news";
import allNews from "components/templates/news/allNews";
import lastNews from "components/templates/news/lastNews";

export default [
  home,
  missions,
  standard,
  who,
  article,
  pageLinks,
  helpDetail,
  process,
  processes,
  homeProcessSites,
  knowEverything,
  contact,
  geolocalisation,
  help,
  glossary,
  homeMairie,
  floatingImage,
  comedec,
  news,
  allNews,
  lastNews,
  helpMairie,
];

export const excludedTemplatesForPageCreation = [home, homeMairie, homeProcessSites, help, contact, comedec];
