import Glossary from "components/templates/glossary/Glossary";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "GLOSSARY",
  label: "N - Lexique",
  Component: Glossary,
  initialContents: [
    {
      key: "glossary",
      type: elementsTypes.GLOSSARY,
      value: "",
      children: [],
    },
  ],
};
