import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/styles";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import { ImageContainer } from "components/templatesComponents/Image";
import Tags from "components/templatesComponents/Tags";
import Hidden from "components/templatesComponents/Hidden";
import PageDate from "components/templatesComponents/PageDate";

const useStyles = makeStyles((theme) => ({
  titleBlock: (props) => ({
    position: "absolute",
    top: props.image ? "-150px" : "0",
    left: 0,
    right: 0,
    "@media print": {
      position: "static",
      top: 0,
    },
  }),
  contentBlock: {
    padding: theme.spacing(1.5, 0),
    backgroundColor: "#fff",
  },
  titleContent: {
    height: "260px",
    width: "60%",
    padding: theme.spacing(4, 0),
    backgroundColor: theme.palette.primary[50],
    "@media print": {
      height: "auto",
      width: "100%",
    },
  },
  titleContentLeftProps: {
    height: "260px",
    backgroundColor: theme.palette.primary[50],
    "@media print": {
      height: "0",
    },
  },
  content: {
    zIndex: 30,
    position: "relative",
    padding: theme.spacing(8, 15, 0, 15),
    boxShadow: "none",
    border: `solid 1px ${theme.palette.componentColors && theme.palette.componentColors[30]}`,
    "@media print": {
      border: "none",
      padding: theme.spacing(2),
    },
  },
  tags: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(5),
  },
}));

const Article = (props) => {
  const { page } = props;
  const { shortDescription, title, image, tags, contents } = page;

  const classes = useStyles({ image });
  const theme = useTheme();

  const { dynamicPart } = contents;

  const iconColor =
    (theme.templates &&
      theme.templates.Article &&
      theme.templates.Article.icon &&
      theme.templates.Article.icon.color) ||
    null;

  return (
    <div style={{ position: "relative" }}>
      <Hidden lgUp>
        <Block>
          <PageTitle>{title}</PageTitle>
          <PageDate page={page} iconColor={iconColor} />
        </Block>
        {image && <ImageContainer ratio={50}>{image}</ImageContainer>}
        <Block className={classes.contentBlock}>
          <Block>{shortDescription}</Block>
          {dynamicPart}
          {tags && <Tags tags={tags} iconColor={iconColor} style={{ paddingLeft: "16px", paddingRight: "16px" }} />}
        </Block>
      </Hidden>

      <Hidden mdDown>
        {image && <ImageContainer ratio={30}>{image}</ImageContainer>}
        <div style={{ position: "relative" }}>
          <div className={classes.titleBlock}>
            <Wrapper leftProps={{ className: classes.titleContentLeftProps }}>
              <div className={classes.titleContent}>
                {/* TODO color titre */}
                <PageTitle style={{ overflowWrap: "break-word", color: theme.palette.common.white }}>{title}</PageTitle>
              </div>
            </Wrapper>
          </div>
          <Wrapper style={{ paddingTop: image ? "0" : "150px" }}>
            <PageDate page={page} position="right" iconColor={iconColor} style={{ height: "50px" }} />
            <Paper square className={classes.content}>
              {shortDescription}
              <br />
              {dynamicPart}
              {tags && <Tags tags={tags} iconColor={iconColor} className={classes.tags} />}
            </Paper>
          </Wrapper>
        </div>
      </Hidden>
    </div>
  );
};

Article.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Article;
