import Comedec from "components/templates/comedec/Comedec";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "COMEDEC",
  label: "G - Comedec",
  Component: Comedec,
  initialContents: [
    {
      key: "dynamicPart",
      type: elementsTypes.DYNAMIC,
      value: "",
      children: [],
    },
  ],
};
