import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Icon from "components/templatesComponents/Icon";
import { makeStyles } from "@material-ui/styles";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  icon: (props) => ({
    color: `${props.iconColor}`,
    fontSize: "16px",
    marginRight: theme.spacing(1),
  }),
  uppercase: {
    "&:first-letter": { textTransform: "uppercase" },
  },
}));

const Tags = (props) => {
  const { tags, noIcon, iconColor, withDSFR, selectedTags, handleSelectTags, ...others } = props;

  const classes = useStyles(props);

  const { language } = useContext(LanguageContext);

  const selected = React.useCallback(
    (s) => {
      return selectedTags.includes(s);
    },
    [selectedTags]
  );

  if (withDSFR) {
    return Array.isArray(tags) && tags.length > 0 ? (
      <ul className="fr-tags-group">
        {tags.map((tag) => (
          <li key={tag}>
            <button
              type="button"
              className="fr-tag"
              aria-pressed={selected(tag) ? "true" : "false"}
              onClick={() => handleSelectTags(tag)}
            >
              <div className={classes.uppercase}>{tag}</div>
            </button>
          </li>
        ))}
      </ul>
    ) : null;
  }

  return (
    tags.length > 0 && (
      <Grid container alignItems="center" {...others}>
        {!noIcon && (
          <Icon
            icon="tag"
            iconDSFR="bookmark-line"
            className={classes.icon}
            title={t[language].components.tag_icon_title}
          />
        )}
        {Array.isArray(tags) &&
          tags.map((tag) => (
            <Box key={tag} component="span" mr={1}>
              <span>#{tag}</span>
            </Box>
          ))}
      </Grid>
    )
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  noIcon: PropTypes.bool,
  iconColor: PropTypes.string,
  withDSFR: PropTypes.bool,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  handleSelectTags: PropTypes.func,
};

Tags.defaultProps = {
  tags: [],
  noIcon: false,
  iconColor: "inherit",
  withDSFR: false,
  selectedTags: [],
  handleSelectTags: null,
};

export default Tags;
