import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "components/templatesComponents/Button";
import Input from "@material-ui/core/Input";
import scrollIntoView from "scroll-into-view-if-needed";
import Icon from "components/templatesComponents/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import { debounce } from "utils/commonUtils";
import MessageContext from "components/MessageContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "white",
    border: "1px solid #bbb",
    height: theme.spacing(7),
    padding: theme.spacing(1, 0.5, 1, 2),
    fontSize: "0.875rem",
    "&.Mui-focused": {
      borderColor: theme.palette.primary.main,
    },
    "& input": {
      height: "1.4rem",
    },
    "& i": {
      color: theme.palette.secondary.main,
    },
  },
  searchBar: {
    minHeight: 56,
    width: 270,
    padding: 0,
    display: "block",
    [theme.breakpoints.up("lg")]: {
      width: 400,
    },
  },
  searchButton: {
    height: "56px",
    marginRight: "-5px",
    "& > span > span": {
      "& > p": {
        marginRight: theme.spacing(1),
      },
      "& > i": {
        fontSize: "1.05rem",
        marginTop: "1.75px",
        color: theme.palette.componentColors[70],
      },
    },
    [theme.breakpoints.up("lg")]: {
      width: 150,
    },
  },
  active: {
    backgroundColor: theme.palette.componentColors[20],
  },
  loader: {
    color: theme.palette.componentColors[70],
  },
  listCity: {
    top: 56,
    position: "absolute",
    border: `1px solid ${theme.palette.componentColors[20]}`,
    backgroundColor: "#fff",
    minWidth: 268,
    maxHeight: 250,
    zIndex: 100,
    // Correctif pour safari :
    WebkitTransform: "translate3d(0,0,0)",
    transform: "translate3d(0,0,0)",
    margin: 0,
    padding: 0,
    overflowX: "hidden",
    overflowY: "auto",
    listStyle: "none",
    "& div": {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(2),
      cursor: "pointer",
    },
    "& div:hover": {
      backgroundColor: theme.palette.componentColors[20],
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 398,
    },
  },
}));

const SearchBar = (props) => {
  const { setPosition, setCityPosition, inputValue, setInputValue, setGeoPoints, setBeforeSearch } = props;
  const classes = useStyles();

  const { language } = useContext(LanguageContext);
  const { displayError } = useContext(MessageContext);

  const [results, setResults] = useState({});
  const [cursor, setCursor] = useState(0);
  const [refList, setRefList] = useState({});
  const [load, setLoad] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const { features = [] } = results || {};
  const { geometry = {}, properties = {} } = features[cursor] || {};
  const { coordinates = [] } = geometry || {};
  const { nom = "" } = properties || {};

  const getInfo = (value, searchType) => {
    axios
      .get(`https://geo.api.gouv.fr/communes?${searchType}=${value}&fields=departement&boost=population&format=geojson`)
      .then(({ data }) => {
        setLoad(false);
        setResults(data);
      })
      .catch(() => {
        displayError(t[language].common.search.error_api_message);
        setResults([]);
      });
  };

  const handleClickPosition = (lat, long, city) => {
    setBeforeSearch(false);
    setPosition([lat, long]);
    setCityPosition(city);
    setGeoPoints([]);
    setResults([]);
    if (/^[A-zÀ-ú\-\s]*$/.test(inputValue)) {
      setInputValue(city);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    setCursor(0);
    if (value && value.length > 1) {
      setLoad(true);
      setResults([]);
      // Mettre en place debounce
      if (/^\d+$/.test(value?.trim())) {
        debounce(getInfo, 350)(value?.trim(), "codePostal");
      } else if (/^[A-zÀ-ú\-\s]*$/.test(value)) {
        debounce(getInfo, 350)(value, "nom");
      } else {
        setLoad(false);
      }
    } else {
      setResults([]);
    }
  };

  useEffect(() => {
    const refs = {};
    if (features?.length > 0) {
      features.forEach((item, i) => {
        refs[i] = React.createRef();
      });
      setRefList(refs);
    }
  }, [features]);

  const handleKey = (e) => {
    const ref = refList[cursor] && refList[cursor].current;
    setMouseOver(false);
    if (e.keyCode === 38 /* Arrow-up */ && cursor > 0) {
      setCursor((prevState) => prevState - 1);
      scrollIntoView(ref, {
        scrollMode: "if-needed",
        block: "end",
        inline: "nearest",
      });
    } else if (e.keyCode === 40 /* Arrow-down */ && cursor < features.length - 1) {
      setCursor((prevState) => prevState + 1);
      scrollIntoView(ref, {
        scrollMode: "if-needed",
        block: "start",
        inline: "nearest",
      });
    } else if (e.keyCode === 13 && coordinates.length >= 2) {
      /* Enter */ handleClickPosition(coordinates[1], coordinates[0], nom);
    }
  };

  const handleMouseOver = () => {
    setMouseOver(!mouseOver);
  };

  const handleClickIcon = () => {
    if (coordinates.length >= 2) {
      handleClickPosition(coordinates[1], coordinates[0], nom);
    }
  };

  return (
    <Toolbar className={classes.searchBar} disableGutters>
      <Input
        onKeyDown={handleKey}
        placeholder={t[language].geolocalisation.placeholder}
        fullWidth
        className={classes.input}
        disableUnderline
        value={inputValue}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <Button
              onClick={handleClickIcon}
              className={classes.searchButton}
              aria-label={load ? t[language].common.search.loading : t[language].common.search.placeholder}
            >
              {load ? (
                <CircularProgress className={classes.loader} size={21} />
              ) : (
                <>
                  {!isMobile && <p>{t[language].common.search.placeholder}</p>}
                  <Icon icon="search" iconDSFR="search-line" title={t[language].common.search.placeholder} />
                </>
              )}
            </Button>
          </InputAdornment>
        }
      />

      {results && features && (
        <div className={classes.listCity} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOver}>
          {features.map((item, i) => (
            <React.Fragment key={item.properties._score}>
              {item.geometry && (
                <div
                  ref={refList[i]}
                  className={!mouseOver && cursor === i ? classes.active : null}
                  onClick={() =>
                    handleClickPosition(item.geometry.coordinates[1], item.geometry.coordinates[0], item.properties.nom)
                  }
                >
                  {item.properties.nom}
                  {item.properties.departement && (
                    <span style={{ color: "rgba(0, 0, 0, 0.54)" }}> {item.properties.departement.nom}</span>
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </Toolbar>
  );
};

SearchBar.propTypes = {
  setPosition: PropTypes.func,
  setBeforeSearch: PropTypes.func,
  setCityPosition: PropTypes.func,
  setInputValue: PropTypes.func,
  setGeoPoints: PropTypes.func,
  inputValue: PropTypes.string,
};

SearchBar.defaultProps = {
  setPosition: {},
  setCityPosition: {},
  setBeforeSearch: PropTypes.func,
  setInputValue: PropTypes.func,
  setGeoPoints: PropTypes.func,
  inputValue: "",
};

export default SearchBar;
