import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import SectionTitle from "components/templatesComponents/SectionTitle";
import Wrapper from "components/LayoutFront/Wrapper";
import HorizontalScroll from "components/templatesComponents/HorizontalScroll";
import SubPageContext from "components/templatesComponents/SubPageContext";
import RectangleBackground from "components/templatesComponents/RectangleBackground";
import KnowEverythingNews from "components/templates/knowEverything/KnowEverythingNews";
import Hidden from "components/templatesComponents/Hidden";

import Separator from "components/templatesComponents/Separator";

const useStyles = makeStyles((theme) => ({
  sectionSubTitle: {
    "& div:not(:first-child)": {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.1rem",
      fontFamily: "Marianne",
      fontWeight: 600,
      lineHeight: 1.6,
    },
  },
  allnews: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(8),
    },
  },
  horizontalContainer: {
    [theme.breakpoints.up("lg")]: {
      margin: "0 -76px",
    },
  },
  allnewsBlock: {
    [theme.breakpoints.up("lg")]: {
      paddingBottom: 0,
    },
  },
  sectionTitleBlock: {
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(1),
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  sectionTitleRegle: {
    marginBottom: 0,
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(2),
    },
  },
  arrow: {
    cursor: "pointer",
    display: "none",
    " & i": {
      fontSize: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
      " & i": {
        fontSize: "32px",
      },
      marginBottom: 103,
    },
  },
  item: {
    display: "inline-block",
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      width: 344,
      paddingRight: theme.spacing(3),
    },
    "&:last-child": {
      paddingRight: 0,
    },
  },
}));

const KnowEverything = (props) => {
  const { page } = props;
  const { title, contents } = page;
  const { topNews, highlighted, allNewsTitle, regulationsTitle, regulations } = contents;
  let { allNews = [] } = contents;
  if (allNews === "") {
    allNews = [];
  }
  if (!Array.isArray(allNews)) {
    allNews = [allNews];
  }

  const classes = useStyles();

  return (
    <Fragment>
      <Wrapper>
        <Block>
          <PageTitle>{title}</PageTitle>
        </Block>
        <Hidden implementation="js" initialWidth="lg" mdDown>
          {highlighted}
          {topNews}
          <Separator />
        </Hidden>
      </Wrapper>
      <RectangleBackground height="400px" position="left" width={0.65}>
        <Wrapper className={classes.allnews}>
          <Block className={classes.allnewsBlock}>
            <SubPageContext.Provider value={{ subTemplate: KnowEverythingNews }}>
              <SectionTitle className={classes.sectionTitle}>{allNewsTitle}</SectionTitle>
              <Hidden implementation="js" initialWidth="lg" mdDown>
                <div className={classes.horizontalContainer}>
                  <HorizontalScroll items={allNews} arrowClass={classes.arrow} itemClass={classes.item} />
                </div>
              </Hidden>
              <Hidden implementation="js" lgUp>
                {highlighted}
                {topNews}
                {allNews}
              </Hidden>
            </SubPageContext.Provider>
          </Block>
        </Wrapper>
      </RectangleBackground>
      <Hidden mdDown>
        <Separator />
      </Hidden>
      <Wrapper>
        <Block className={classes.sectionTitleBlock}>
          <SectionTitle className={classes.sectionTitleRegle}>{regulationsTitle}</SectionTitle>
        </Block>
      </Wrapper>
      <RectangleBackground top="96px" bottom="50px" position="right" backgroundColor="#f1f1f1" width={0.65}>
        <Wrapper>
          <Block className={classes.sectionSubTitle}>
            {(regulations || []).map((reg) => (
              <div key={reg.id}>
                <h3>{reg.title}</h3>
                {reg.decrees}
              </div>
            ))}
          </Block>
        </Wrapper>
      </RectangleBackground>
    </Fragment>
  );
};

KnowEverything.propTypes = {
  page: PropTypes.shape(),
};

KnowEverything.defaultProps = {
  page: {},
};

export default KnowEverything;
