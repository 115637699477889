import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Captcha from "components/Captcha";
import { makeStyles } from "@material-ui/styles";
import Button from "components/templatesComponents/Button";
import Modal, { ModalActions } from "components/templatesComponents/Modal";
import PageVersionContext from "components/PageVersionContext";
import ContactService from "services/ContactService";
import LinearProgress from "@material-ui/core/LinearProgress";
import languages from "utils/languagesTypes";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
  relative: {
    position: "relative",
  },
  loader: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const Mailto = (props) => {
  const classes = useStyles();
  const { text, recipient, subject, body, contentId } = props;
  const { currentPageVersion } = useContext(PageVersionContext);
  const { id: pageId } = currentPageVersion;
  const [loader, setLoader] = useState(false);
  const [captchaMessage, setCaptchaMessage] = useState("");
  const [openModal, setOpenModal] = useState("");

  const language =
    typeof localStorage !== "undefined" && localStorage.getItem("language") !== "null"
      ? localStorage.getItem("language")
      : Object.keys(languages)[0];

  const sendCandidate = async () => {
    setLoader(true);
    if (process.env.visualcaptcha_secret_key) {
      if (window) {
        setOpenModal(true);
      }
    } else {
      if (recipient) {
        window.location.href = `mailto:${recipient}?subject=${subject}&body=${body}`;
      } else {
        const webmail = await ContactService.getWebmail({ contentId, pageId });
        if (webmail) {
          window.location.href = webmail;
        }
      }
      setLoader(false);
    }
  };

  const onModalClose = () => {
    setOpenModal(false);
    setLoader(false);
    setCaptchaMessage("");
  };

  const checkCaptcha = async (formData) => {
    const captchaResponse = await ContactService.verifyCaptcha(formData, language);
    if (captchaResponse && captchaResponse.status === 200) {
      const webmail = await ContactService.getWebmail({ contentId, pageId });
      if (webmail) {
        window.location.href = webmail;
        onModalClose();
      }
    } else {
      setCaptchaMessage(captchaResponse.message);
    }
  };

  return (
    <div className={classes.button}>
      {process.env.visualcaptcha_secret_key && (
        <Modal open={openModal} onClose={onModalClose} size="md">
          <form
            action="/"
            method="POST"
            onSubmit={(e) => {
              e.preventDefault();
              checkCaptcha(new FormData(e.target));
            }}
          >
            <Captcha id="sample-captcha" captchaMessage={captchaMessage} />

            <ModalActions>
              <Button outlined onClick={onModalClose}>
                {t[language].components.cancel_button_label}
              </Button>
              <Button type="submit">{t[language].components.validate_button_label}</Button>
            </ModalActions>
          </form>
        </Modal>
      )}
      <Button onClick={() => sendCandidate()} disabled={loader} className={classes.relative}>
        {text}
        {loader && <LinearProgress className={classes.loader} />}
      </Button>
    </div>
  );
};

Mailto.propTypes = {
  text: PropTypes.string.isRequired,
  recipient: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.string,
  contentId: PropTypes.string.isRequired,
};

Mailto.defaultProps = {
  recipient: "",
  subject: "",
  body: "",
};

export default Mailto;
