import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { HashLink } from "react-router-hash-link";
import PageVersionContext from "components/PageVersionContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SiteContext from "components/SiteContext";
import ATInternetService from "services/ATInternetService";
import EulerianService from "services/EulerianService";
import JsonLd from "components/JsonLd";
import templates from "components/templates/templates";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const BreadcrumbItem = (props) => {
  const { item, ...others } = props;
  return (
    <li title={item.title}>
      <HashLink className="fr-breadcrumb__link" title={item.title} to={item.fullPath || "/"} {...others}>
        {item.title}
      </HashLink>
    </li>
  );
};

BreadcrumbItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    fullPath: PropTypes.string,
  }).isRequired,
};

const Breadcrumb = () => {
  const { currentPageVersion } = useContext(PageVersionContext);
  const { breadcrumb = [] } = currentPageVersion || {};
  const { language } = useContext(LanguageContext);
  const site = useContext(SiteContext);
  const { name: siteName } = site;

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  const [displayBreadcrumb, setDisplayBreadcrumb] = useState(false);

  useEffect(() => {
    if (currentPageVersion) {
      const templateDefinition = templates.find((template) => template.key === currentPageVersion.template);

      if (breadcrumb && templateDefinition) {
        const pageTag = {
          name: breadcrumb.length > 1 ? breadcrumb[breadcrumb.length - 1].title : "accueil",
        };

        if (breadcrumb.length === 1) {
          pageTag.chapter2 = "accueil";
        }

        Object.keys(breadcrumb).forEach((key) => {
          if (key > 0 && key < breadcrumb.length - 1) {
            pageTag[`chapter${Number(key) + 1}`] = breadcrumb[key].title;
          }
        });

        if (templateDefinition.servesOtherPages || breadcrumb.length <= 2) {
          pageTag.name = "accueil";
          pageTag[`chapter${breadcrumb.length}`] = breadcrumb[breadcrumb.length - 1].title;
        } else if (templateDefinition.containsOnlyOnePage && breadcrumb.length > 2) {
          pageTag.name = breadcrumb[breadcrumb.length - 1].title;
          pageTag[`chapter${breadcrumb.length}`] = pageTag.name;
        }

        ATInternetService.sendPage(pageTag);

        const infoTag = {
          page_name: breadcrumb.length > 1 ? breadcrumb[breadcrumb.length - 1].title : "accueil",
        };

        if (breadcrumb.length === 1) {
          infoTag.page_category2 = "accueil";
        }

        Object.keys(breadcrumb).forEach((key) => {
          if (key > 0 && key < breadcrumb.length - 1) {
            infoTag[`page_category${Number(key) + 1}`] = breadcrumb[key].title;
          }
        });

        if (templateDefinition.servesOtherPages || breadcrumb.length <= 2) {
          infoTag.page_name = "accueil";
          infoTag[`page_category${breadcrumb.length}`] = breadcrumb[breadcrumb.length - 1].title;
        } else if (templateDefinition.containsOnlyOnePage && breadcrumb.length > 2) {
          infoTag.page_name = breadcrumb[breadcrumb.length - 1].title;
          infoTag[`page_category${breadcrumb.length}`] = infoTag.page_name;
        }

        EulerianService.sendPage(infoTag, siteName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageVersion && currentPageVersion.id]);

  const structuredData = `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [${
      breadcrumb &&
      breadcrumb.reduce(
        (str, breadcrumbItem, index) =>
          `${str}
        {
          "@type": "ListItem",
          "position": ${index + 1},
          "name": "${breadcrumbItem.title}",
          "item": "${site.host}${breadcrumbItem.fullPath || "/"}"
        }${breadcrumb.length !== index + 1 ? "," : ""}`,
        ""
      )
    }
    ]
  }`;

  return (
    breadcrumb &&
    breadcrumb.length > 1 && (
      <>
        <nav role="navigation" className="fr-breadcrumb" aria-label={t[language].components.breadcrumb}>
          {isMobile && !displayBreadcrumb ? (
            <button type="button" className="fr-breadcrumb__button" onClick={() => setDisplayBreadcrumb(true)}>
              Voir le fil d’Ariane
            </button>
          ) : (
            <ol className="fr-breadcrumb__list">
              {breadcrumb.map((breadcrumbItem, index) =>
                index !== breadcrumb.length - 1 ? (
                  <BreadcrumbItem key={breadcrumbItem.pageId} item={breadcrumbItem} />
                ) : (
                  <li key={breadcrumbItem.pageId}>
                    <a title={breadcrumbItem.title} className="fr-breadcrumb__link" aria-current="page">
                      {breadcrumbItem.title}
                    </a>
                  </li>
                )
              )}
            </ol>
          )}
        </nav>
        <JsonLd structuredData={structuredData} />
      </>
    )
  );
};

Breadcrumb.propTypes = {};

Breadcrumb.defaultProps = {};

export default Breadcrumb;
