import React, { useState, Fragment, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import Icon from "components/templatesComponents/Icon";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import SectionTitle from "components/templatesComponents/SectionTitle";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Hidden from "components/templatesComponents/Hidden";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "components/templatesComponents/Select";
import Tabs from "components/templatesComponents/Tabs";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import { stringToPath } from "utils/urlUtils";
import Button from "components/templatesComponents/Button";
import CardContext from "components/templatesComponents/CardContext";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import {
  eventKey,
  getTranslateIndex,
  immatSivRegex,
  immatCycloRegex,
  permisRegex,
  checkFrenchNameRules,
  checkEmailRules,
  checkMessageLength,
  stringToSnakeCaseString,
} from "utils/commonUtils";
import ContactService from "services/ContactService";
import scrollIntoView from "scroll-into-view-if-needed";
import CircularProgress from "@material-ui/core/CircularProgress";
import Captcha from "components/Captcha";
import ATInternetService from "services/ATInternetService";
import EulerianService from "services/EulerianService";
import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  othersQuestionsSection: {
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.componentColors[10],
      paddingBottom: "0 !important",
    },
  },
  othersQuestionsWrapper: {
    zIndex: 1,
    position: "relative",
  },
  image: (props) => ({
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: props.questionTitle ? "264px" : "204px",
      right: 0,
      bottom: 0,
      width: "68%",
    },
  }),
  section: {
    padding: 0,
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(8, 0),
    },
  },
  inputForm: {
    backgroundColor: "white",
    border: `1px solid ${theme.palette.componentColors[30]}`,
    padding: theme.spacing(2),
    minHeight: "56px",
    fontSize: "1em",
    "&.Mui-focused": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-error": {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  inputAdornmentIconError: {
    color: theme.palette.error.main,
    fontSize: "1.5rem",
  },
  formWrapper: {
    display: "flex",
  },
  formLeftSideTitle: {
    "& > *": {
      color: theme.palette.common.white,
    },
  },
  formLeftSideSubPart: {
    backgroundColor: "#fff",
    flex: "1 1 auto",
    [theme.breakpoints.up("lg")]: {
      "& *": {
        color: theme.palette.common.white,
      },
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      borderRight: `1px solid ${theme.palette.componentColors[30]}`,
      borderLeft: `1px solid ${theme.palette.componentColors[30]}`,
    },
  },
  formLeftSideSubtitle: {
    fontSize: "1.1rem",
    fontWeight: 600,
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.25rem",
      padding: theme.spacing(1, 4, 3, 4),
    },
    padding: theme.spacing(3, 2, 2, 2),
  },
  formLeftSideDelay: {
    backgroundColor: "#4E63C7",
    fontSize: "1rem",
    color: "#fff",
    fontWeight: 600,
    padding: theme.spacing(1, 4),
    display: "flex",
    "& > div:first-child": {
      paddingRight: theme.spacing(1.25),
    },
  },
  formLeftSideDescription: {
    padding: theme.spacing(2, 2, 1, 2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2, 4),
    },
  },
  formRightSide: {
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.primary[10],
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      borderBottom: `1px solid ${theme.palette.componentColors[30]}`,
      borderRight: `1px solid ${theme.palette.componentColors[30]}`,
      borderLeft: `1px solid ${theme.palette.componentColors[30]}`,
    },
    backgroundColor: "#fff",
    padding: theme.spacing(2, 2, 4, 2),
    position: "relative",
  },
  formWidth: {
    width: "100%",
  },
  formValidated: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(10, 0),
  },
  formValidatedIcon: {
    fontSize: "48px",
    color: "#24821a",
    "&::before": {
      "--icon-size": "3rem",
    },
  },
  contactModeWrapper: {
    flexDirection: "row",
  },
  contactModeItem: {
    display: "flex",
    cursor: "pointer",
  },
  contactForm: {
    backgroundColor: theme.palette.primary.main,
    "& *": {
      color: theme.palette.common.white,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(4, 0, 0, 4),
      flexDirection: "row",
    },
  },
  contactMode: {
    backgroundColor: theme.palette.primary.main,
    "& *": {
      color: theme.palette.common.white,
    },
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(4),
      flexDirection: "row",
    },
  },
  contactModeIcon: {
    fontSize: "1.5rem",
    padding: theme.spacing(1),
  },
  contactModeTitle: {
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      textAlign: "left",
    },
  },
  contactModeArrowIcon: {
    fontSize: "2rem",
  },
  contactModeArrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contactModeDetail: {
    flex: 1,
    backgroundColor: theme.palette.primary[10],
    padding: theme.spacing(8),
  },
  contactModeDetailElement: {
    paddingBottom: theme.spacing(3),
  },
  phoneNumber: {
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing(3, 0),
  },
  phoneIcon: {
    fontSize: "1.3rem",
    color: theme.palette.secondary.main,
    paddingRight: theme.spacing(1.5),
  },
  subTitle: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  TabsOtherQuestion: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      minHeight: "350px",
    },
  },
  sectionTitleOthers: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(2.5),
    },
  },
  othersQuestionsTabs: {
    minWidth: 0,
    padding: 0,
    marginRight: theme.spacing(4),
    textTransform: "none",
    fontSize: "0.875rem",
    "button&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focusVisible": {
      border: "2px solid #0a76f6",
    },
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "#eff4fd",
    opacity: "0.5",
    zIndex: 1000,
    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  separator: {
    marginBottom: theme.spacing(4),
  },
  close: {
    paddingLeft: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  requiredFields: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(2),
    fontSize: "0.75rem",
  },
}));

const HelpForm = (props) => {
  const { page } = props;
  const { title, image, contents, id: pageId } = page;
  const {
    questionTitle,
    othersQuestionsTitle,
    othersQuestions = {},
    contactTitle,
    contactText,
    contactBtn,
    contactUsTitle,
    ContactFormProfilesList = {},
    ContactFormSubjectsList = {},
    contactForm,
    contactPhone = {},
    contactDigitalPoints = {},
  } = contents;

  let { tabs: othersQuestionsTabs = [] } = othersQuestions;

  const displayTabsCards = typeof othersQuestionsTitle === "object" || othersQuestionsTabs.length > 0;

  const displayTabsCardsBlock = questionTitle || othersQuestionsTitle || displayTabsCards;

  const {
    id: contactPhoneId,
    title: contactPhoneTitle,
    subtitle: contactPhoneSubtitle,
    detail: contactPhoneDetail = {},
  } = contactPhone;

  const { phone1, phone2, phone3, deafAccess } = contactPhoneDetail;

  const { title: contactDigitalPointsTitle, detail: contactDigitalPointsDetail = {} } = contactDigitalPoints;

  const { text, digitalPoint } = contactDigitalPointsDetail;

  const classes = useStyles({ questionTitle });

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const site = useContext(SiteContext);
  const { language } = useContext(LanguageContext);
  const { name: siteName } = site;

  // Others questions
  if (!Array.isArray(othersQuestionsTabs)) {
    othersQuestionsTabs = [othersQuestionsTabs];
  }
  othersQuestionsTabs = othersQuestionsTabs.map((tab) => ({
    ...tab,
    url: stringToPath(tab.title),
    children: (
      <Grid container item xs={12} lg={6} spacing={isMobile ? 1 : 2}>
        {tab.children}
      </Grid>
    ),
  }));

  const modes = [];
  if (Object.keys(contactPhone).length) {
    contactPhone.icons = [{ icon: "phone" }];
    modes.push(contactPhone);
  }
  if (Object.keys(contactDigitalPoints).length) {
    contactDigitalPoints.icons = [{ icon: "building" }];
    modes.push(contactDigitalPoints);
  }

  const [expandedContactModeItem, setExpandedContactModeItem] = useState(null);
  const isOpen = (contactMode) =>
    expandedContactModeItem && contactMode && contactMode.id === expandedContactModeItem.id;
  const handleContactModeClick = (mode) => {
    if (expandedContactModeItem === mode) {
      setExpandedContactModeItem(null);
    } else {
      setExpandedContactModeItem(mode);

      if (typeof contactPhoneTitle === "string" && mode.id === contactPhoneId) {
        ATInternetService.sendClick({
          name: "affichage_informations",
          chapter2: "contact",
          chapter3: "telephone",
          type: "action",
        });
        EulerianService.sendClick({
          button: [`button::click::${contactPhoneTitle.toLowerCase()}`],
        });
      }
    }
  };

  const isContactModeClickDisabled = useMemo(() => !isMobile && modes.length === 1, [isMobile, modes]);

  useEffect(() => {
    if (isContactModeClickDisabled) {
      setExpandedContactModeItem(modes[0]);
    }
  }, [modes, isContactModeClickDisabled]);

  // Contact form
  const [form, setForm] = useState({
    name: "",
    firstName: "",
    profile: "",
    email: "",
    subject: "",
    numImmat: "",
    numPermis: "",
    message: "",
  });
  const [fileForm, setFileForm] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSended, setFormSended] = useState(false);
  const { id: profileId } = ContactFormProfilesList;
  const { id: subjectId } = ContactFormSubjectsList;
  const [loader, setLoader] = useState(false);
  const [sendError, setSendError] = useState(null);
  const [mailSendActivated, setMailSendActivated] = useState(false);
  const [captchaMessage, setCaptchaMessage] = useState("");
  const [sendTag, setSendTag] = useState(false);

  let { ContactFormProfiles = [] } = ContactFormProfilesList;
  if (ContactFormProfiles && !Array.isArray(ContactFormProfiles)) {
    ContactFormProfiles = [ContactFormProfiles];
  }

  let { ContactFormSubjects = [] } = ContactFormSubjectsList;
  if (ContactFormSubjects && !Array.isArray(ContactFormSubjects)) {
    ContactFormSubjects = [ContactFormSubjects];
  }

  useEffect(() => {
    if (form.subject !== "") {
      setMailSendActivated(form.subject && !form.subject.autoReply && process.env.mail_send_enable !== "true");
    }
  }, [form.subject]);

  const fileType = fileForm && fileForm.type.split("/");
  const authorizedFileType = process.env.mail_allowed_attachment_type.includes(fileType && fileType[1]);

  const errors = useMemo(() => {
    const { name, firstName, profile, email, subject, numImmat, numPermis, message } = form;

    return {
      name: !checkFrenchNameRules({ key: name, length: 100 }),
      firstName: !checkFrenchNameRules({ key: firstName, length: 40 }),
      profile: !profile,
      email: !checkEmailRules({ key: email }),
      subject: !subject,
      numImmat:
        ["IMMAT", "PHA"].includes(siteName) &&
        numImmat &&
        !(numImmat.match(immatSivRegex) || numImmat.match(immatCycloRegex)),
      numPermis: ["PERMIS", "AUTO_ECOLE"].includes(siteName) && numPermis && !numPermis.match(permisRegex),
      message: form && form.subject && !form.subject.autoReply && !checkMessageLength({ key: message }),
      fileForm:
        subject &&
        !subject.autoReply &&
        fileForm &&
        fileForm.size &&
        (fileForm.size >= process.env.mail_attachment_size || !authorizedFileType),
    };
  }, [form, fileForm, siteName, authorizedFileType]);

  const showError = (key) => formSubmitted && errors[key];
  const InputAdornmentError = (
    <InputAdornment position="end">
      <Icon
        icon="exclamation-circle"
        iconDSFR="error-warning-line"
        className={classes.inputAdornmentIconError}
        title={t[language].help.error}
      />
    </InputAdornment>
  );

  const handleFormChange = (e) => {
    const { value, name: fieldName } = e.target;
    setForm({
      ...form,
      [fieldName]: fieldName === "numImmat" ? value.toUpperCase() : value,
    });
  };

  if (typeof document !== "undefined") {
    const formSide = document.getElementById("formRightSide");
    if (formSide && !isMobile) {
      const formHeight = formSide.clientHeight;
      document.getElementById("formRightSide").style.minHeight =
        // eslint-disable-next-line prefer-template
        formHeight + "px";
    }
  }

  const ScrollSent = () => {
    scrollIntoView(document.getElementById("contactForm"), {
      block: "nearest",
      inline: "nearest",
    });
  };

  const isFormValid = Object.values(errors).reduce((a, b) => a && !b, true);

  const sendMail = () => {
    const formData = new FormData();
    if (fileForm) {
      formData.append("attachment", fileForm, fileForm.name);
    }
    formData.append("siteName", siteName);
    formData.append("name", form.name);
    formData.append("firstName", form.firstName);
    formData.append("email", form.email);
    formData.append("message", form.message);
    formData.append("profileTitle", form.profile && form.profile.title);
    formData.append("subjectTitle", form.subject && form.subject.title);
    formData.append("numImmat", form.numImmat);
    formData.append("numPermis", form.numPermis);
    formData.append("contentId", subjectId);
    formData.append("pageId", pageId);
    formData.append("subjectId", form.subject && form.subject.id);
    if (form.subject && !form.subject.autoReply) {
      ContactService.postMail(formData).then((response) => {
        if (response && response.status && response.status === 400) {
          setSendError(response.message);
        } else {
          setFormSended(true);
        }
        setLoader(false);
      });
    } else {
      setFormSended(true);
      setLoader(false);
    }
  };

  const checkCaptcha = async (formData) => {
    const captchaResponse = await ContactService.verifyCaptcha(formData, language);
    if (captchaResponse && captchaResponse.status === 200) {
      sendMail();
      if (form.subject.autoReply) {
        ATInternetService.sendPage({
          name: "affichage_reponse_automatique",
          chapter2: "contact",
          customVars: {
            site: {
              1: `[${stringToSnakeCaseString(form.profile.title)}]`,
              2: `[${stringToSnakeCaseString(form.subject.title)}]`,
            },
          },
        });
        EulerianService.sendPage(
          {
            page_name: "affichage_reponse_automatique",
            page_category2: stringToSnakeCaseString(title),
            path: "/affichage_reponse_automatique",
            referrer: typeof document !== "undefined" ? document?.location?.pathname : "",
            page_title: "affichage_reponse_automatique",
          },
          siteName
        );
      } else {
        ATInternetService.sendClick({
          name: "envoyer_votre_message",
          chapter2: "contact",
          type: "action",
        });
        EulerianService.sendClick({ button: [`button::action::envoyer votre message`] });
      }
    } else {
      setLoader(false);
      setCaptchaMessage(captchaResponse.message);
    }
  };

  const handleValidate = (formData) => {
    ScrollSent();
    setFormSubmitted(true);
    if (isFormValid) {
      setLoader(true);
      if (!process.env.visualcaptcha_secret_key) {
        sendMail();
      } else {
        checkCaptcha(formData);
      }
    }
  };

  const handleUploadFile = (e) => {
    const fileToUpload = e.target.files[0];
    setFileForm(fileToUpload);
  };

  const handleBackToForm = () => {
    setForm({
      ...form,
      subject: "",
      message: "",
    });
    setFormSubmitted(false);
    setFormSended(false);
    EulerianService.sendClick({ button: [`button::click::retour au formulaire`] });
  };

  const handleKeyDown = (event, mode) => {
    if (event.key === eventKey) {
      handleContactModeClick(mode);
    }
  };

  const handleClickDigitalPoint = () => {
    ATInternetService.sendClick({
      name: "trouver_un_point_numerique",
      chapter2: "contact",
      type: "action",
    });
    EulerianService.sendClick({ button: [`button::click::trouver un point numerique`] });
  };

  const handleClickDeafAccess = () => {
    ATInternetService.sendClick({
      name: "acces_sourds_malentendants",
      chapter2: "contact",
      chapter3: "telephone",
      type: "action",
    });
    EulerianService.sendClick({ button: [`button::click::acces sourds malentendants`] });
  };

  return (
    <Fragment>
      {displayTabsCardsBlock && (
        <div className={classnames(classes.othersQuestionsSection, classes.section)}>
          <Wrapper className={classes.othersQuestionsWrapper}>
            <Block>
              {questionTitle && <SectionTitle>{questionTitle}</SectionTitle>}
              {othersQuestionsTitle && (
                <h3 className={classnames(classes.sectionTitleOthers, questionTitle ? "" : "fr-h2")}>
                  {othersQuestionsTitle}
                </h3>
              )}
              {displayTabsCards && (
                <div className={classes.TabsOtherQuestion}>
                  <CardContext.Provider
                    value={{
                      desktopStyleKey: styleKeys.HORIZONTAL,
                      mobileStyleKey: styleKeys.HORIZONTAL,
                      Wrapper: Grid,
                      wrapperProps: {
                        item: true,
                        xs: 12,
                      },
                    }}
                  >
                    <Tabs
                      {...othersQuestions}
                      variant="scrollable"
                      tabsProps={{ className: classes.othersQuestionsTabs }}
                      tabs={othersQuestionsTabs}
                      sendTag={sendTag}
                      setSendTag={setSendTag}
                    />
                  </CardContext.Provider>
                </div>
              )}
            </Block>
          </Wrapper>
          <Hidden mdDown>{image && <div className={classes.image}>{image}</div>}</Hidden>
        </div>
      )}
      <Wrapper className={classes.section}>
        <Block>
          <SectionTitle>{contactTitle}</SectionTitle>
          {contactText && <Box mb={2}>{contactText}</Box>}
          {contactBtn && <Box>{contactBtn}</Box>}
          {(contactText || contactBtn) && <div className={classes.separator}> </div>}
          <h3 className={classes.subTitle}>{contactUsTitle}</h3>
          <Grid container direction="column" spacing={4}>
            {/* FORMULAIRE */}
            <Grid item container className={classes.formWrapper}>
              <Grid item xs={12} lg={4} container direction="column">
                <div
                  className={classes.contactForm}
                  onClick={() => handleContactModeClick(contactForm)}
                  id="contactForm"
                >
                  <Hidden lgUp>
                    <Icon
                      className={classes.contactModeIcon}
                      icon="file-alt"
                      iconDSFR="file-text-line"
                      title={t[language].help.form}
                    />
                  </Hidden>
                  <div className={classes.formLeftSideTitle}>
                    <p className={classnames(classes.contactModeTitle, isMobile ? "" : "fr-h3")} role="presentation">
                      {contactForm.title}
                    </p>
                  </div>
                  <Hidden lgUp>
                    <div className={classes.contactModeArrow}>
                      <Icon
                        icon={isOpen(contactForm) ? "chevron-left" : "chevron-right"}
                        iconDSFR={isOpen(contactForm) ? "arrow-up-s-line" : "arrow-down-s-line"}
                        className={classes.contactModeArrowIcon}
                        isHidden={false}
                        aria-label={isOpen(contactForm) ? t[language].common.close : t[language].common.open}
                      />
                    </div>
                  </Hidden>
                </div>
                {(!isMobile || (isOpen(contactForm) && !formSended)) && (
                  <div className={classes.formLeftSideSubPart}>
                    <div className={classes.formLeftSideSubtitle}>{contactForm.subTitle}</div>
                    <div className={classes.formLeftSideDelay}>
                      <div>
                        <Icon icon="clock" iconDSFR="time-line" title={t[language].help.deadline} />
                      </div>
                      <div>{contactForm.delay}</div>
                    </div>
                    <p className={classes.formLeftSideDescription} role="presentation">
                      {contactForm.description}
                    </p>
                  </div>
                )}
              </Grid>
              {(!isMobile || isOpen(contactForm)) && (
                <Grid
                  item
                  xs={12}
                  lg={8}
                  container
                  justifyContent="space-between"
                  className={classes.formRightSide}
                  id="formRightSide"
                >
                  {!formSended && (
                    <form
                      action="/"
                      method="POST"
                      className={classes.formWidth}
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleValidate(new FormData(e.target));
                      }}
                    >
                      {loader && (
                        <Grid container justifyContent="center" alignItems="center" className={classes.loader}>
                          <CircularProgress />
                        </Grid>
                      )}
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                          <FormControl error={showError("name")}>
                            <label role="presentation" htmlFor="name" className="fr-text--bold">
                              {t[language].help.name} *
                            </label>
                            <Input
                              type="text"
                              name="name"
                              id="name"
                              value={form.name}
                              placeholder={t[language].help.name}
                              onChange={handleFormChange}
                              error={showError("name")}
                              disableUnderline
                              endAdornment={showError("name") && InputAdornmentError}
                              className={classes.inputForm}
                              inputProps={{
                                spellCheck: "false",
                              }}
                            />
                            {showError("name") && (
                              <FormHelperText>
                                {form.name.length > 100
                                  ? `100 ${t[language].help.max_characters}`
                                  : t[language].help.name_error_message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <FormControl error={showError("firstName")}>
                            <label role="presentation" htmlFor="firstName" className="fr-text--bold">
                              {t[language].help.first_name} *
                            </label>
                            <Input
                              type="text"
                              name="firstName"
                              id="firstName"
                              value={form.firstName}
                              placeholder={t[language].help.first_name}
                              onChange={handleFormChange}
                              error={showError("firstName")}
                              disableUnderline
                              className={classes.inputForm}
                              endAdornment={showError("firstName") && InputAdornmentError}
                              inputProps={{
                                spellCheck: "false",
                              }}
                            />
                            {showError("firstName") && (
                              <FormHelperText>
                                {form.firstName.length > 40
                                  ? `40 ${t[language].help.max_characters}`
                                  : t[language].help.first_name_error_message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl error={showError("profile")}>
                            <label role="presentation" htmlFor="profile" className="fr-text--bold">
                              {t[language].help.profil_label} *
                            </label>
                            <Select
                              label={t[language].help.profil}
                              name="profile"
                              id={profileId}
                              value={form.profile}
                              onChange={handleFormChange}
                              fullWidth
                              disableUnderline
                              displayEmpty
                              endAdornment={showError("profile") && InputAdornmentError}
                              className={classes.inputForm}
                              Wrapper={ContactFormProfilesList.Wrapper}
                              wrapperProps={ContactFormProfilesList.wrapperProps}
                            >
                              <MenuItem value="" disabled>
                                <em>{t[language].help.profil_placeholder}</em>
                              </MenuItem>
                              {ContactFormProfiles.map(
                                (profile) =>
                                  profile.title && (
                                    <MenuItem value={profile} key={profile.id}>
                                      {profile.title}
                                    </MenuItem>
                                  )
                              )}
                            </Select>
                            {showError("profile") && (
                              <FormHelperText>
                                {t[language].common.required_fields[getTranslateIndex(1)]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl error={showError("email")}>
                            <label role="presentation" htmlFor="email" className="fr-text--bold">
                              {t[language].help.mail} *
                            </label>
                            <Input
                              type="email"
                              name="email"
                              id="email"
                              value={form.email}
                              placeholder={t[language].help.mail}
                              onChange={handleFormChange}
                              error={showError("email")}
                              disableUnderline
                              endAdornment={showError("email") && InputAdornmentError}
                              className={classes.inputForm}
                              inputProps={{
                                spellCheck: "false",
                              }}
                            />
                            {showError("email") && (
                              <FormHelperText>
                                {form.email.length > 80
                                  ? `80 ${t[language].help.max_characters}`
                                  : t[language].help.mail_error_message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl error={showError("subject")}>
                            <label role="presentation" htmlFor="subject" className="fr-text--bold">
                              {t[language].help.object} *
                            </label>
                            <Select
                              label={t[language].help.object}
                              name="subject"
                              id={subjectId}
                              value={form.subject}
                              onChange={handleFormChange}
                              fullWidth
                              disableUnderline
                              displayEmpty
                              endAdornment={showError("subject") && InputAdornmentError}
                              className={classes.inputForm}
                              Wrapper={ContactFormSubjectsList.Wrapper}
                              wrapperProps={ContactFormSubjectsList.wrapperProps}
                            >
                              <MenuItem value="" disabled>
                                <em>{t[language].help.object_placeholder}</em>
                              </MenuItem>
                              {ContactFormSubjects.map(
                                (subject) =>
                                  subject.title &&
                                  (subject.recipient || subject.autoReply) && (
                                    <MenuItem value={subject} key={subject.id}>
                                      {subject.title}
                                    </MenuItem>
                                  )
                              )}
                            </Select>
                            {showError("subject") && (
                              <FormHelperText>
                                {t[language].common.required_fields[getTranslateIndex(1)]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        {["IMMAT", "PHA"].includes(siteName) && (
                          <Grid item xs={12}>
                            <FormControl error={showError("numImmat")}>
                              <label role="presentation" htmlFor="numImmat" className="fr-text--bold">
                                {t[language].help.immat_number}
                              </label>
                              <Input
                                type="text"
                                name="numImmat"
                                id="numImmat"
                                value={form.numImmat}
                                placeholder={t[language].help.immat_number}
                                onChange={handleFormChange}
                                error={showError("numImmat")}
                                disableUnderline
                                endAdornment={showError("numImmat") && InputAdornmentError}
                                className={classes.inputForm}
                                inputProps={{
                                  spellCheck: "false",
                                }}
                              />
                              {showError("numImmat") && (
                                <FormHelperText>{t[language].help.immat_number_error_message}</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        )}
                        {["PERMIS", "AUTO_ECOLE"].includes(siteName) && (
                          <Grid item xs={12}>
                            <FormControl error={showError("numPermis")}>
                              <label role="presentation" htmlFor="numPermis" className="fr-text--bold">
                                {t[language].help.license_number}
                              </label>
                              <Input
                                type="text"
                                name="numPermis"
                                id="numPermis"
                                value={form.numPermis}
                                placeholder={t[language].help.license_number}
                                onChange={handleFormChange}
                                error={showError("numPermis")}
                                disableUnderline
                                endAdornment={showError("numPermis") && InputAdornmentError}
                                className={classes.inputForm}
                                inputProps={{
                                  spellCheck: "false",
                                }}
                              />
                              {showError("numPermis") && (
                                <FormHelperText>{t[language].help.license_number_error_message}</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        )}
                        {form && form.subject && !form.subject.autoReply && process.env.mail_send_enable === "true" && (
                          <Grid item xs={12}>
                            <FormControl error={showError("message")}>
                              <label role="presentation" htmlFor="message" className="fr-text--bold">
                                {t[language].help.message} *
                              </label>
                              <Input
                                type="text"
                                name="message"
                                id="message"
                                value={form.message}
                                placeholder={t[language].help.message}
                                onChange={handleFormChange}
                                multiline
                                rows={6}
                                disableUnderline
                                error={showError("message")}
                                endAdornment={showError("message") && InputAdornmentError}
                                className={classes.inputForm}
                                inputProps={{
                                  spellCheck: "false",
                                }}
                              />
                              {showError("message") && (
                                <FormHelperText>
                                  {form.message.length > 2000
                                    ? `2000 ${t[language].help.max_characters}`
                                    : t[language].common.required_fields[getTranslateIndex(1)]}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        )}

                        {form && form.subject && !form.subject.autoReply && process.env.mail_send_enable === "true" && (
                          <Grid item xs={12}>
                            <input
                              accept={process.env.mail_allowed_attachment_type}
                              id="input-file-attachment"
                              name="input-file-attachment"
                              type="file"
                              style={{ display: "none" }}
                              onChange={handleUploadFile}
                            />
                            <label role="presentation" htmlFor="input-file-attachment">
                              <Button component="span">
                                {`${t[language].help.pj_prefix} (${process.env.mail_allowed_attachment_type})`}
                              </Button>
                            </label>
                            {fileForm && fileForm.name && (
                              <FormHelperText>
                                <Icon icon="paperclip" iconDSFR="attachment-line" title={t[language].help.pj} />{" "}
                                {fileForm.name}{" "}
                                <Icon
                                  icon="times"
                                  iconDSFR="close-line"
                                  tabIndex={0}
                                  onClick={() => {
                                    setFileForm(null);
                                  }}
                                  onKeyDown={(e) => e.key === eventKey && setFileForm(null)}
                                  className={classes.close}
                                  isHidden={false}
                                  aria-label={t[language].help.pj_delete_message}
                                />
                              </FormHelperText>
                            )}
                            {showError("fileForm") && (
                              <FormHelperText error>
                                {fileForm.size >= process.env.mail_attachment_size && (
                                  <>
                                    <Icon
                                      icon="exclamation-circle"
                                      iconDSFR="error-warning-line"
                                      title={t[language].help.error}
                                    />
                                    &nbsp;{t[language].help.pj_error_message}
                                  </>
                                )}
                                {fileForm.size >= process.env.mail_attachment_size && !authorizedFileType && <br />}
                                {!authorizedFileType && (
                                  <>
                                    <Icon
                                      icon="exclamation-circle"
                                      iconDSFR="error-warning-line"
                                      title={t[language].help.error}
                                    />
                                    &nbsp;{t[language].help.pj_unauthorized_message}
                                  </>
                                )}
                              </FormHelperText>
                            )}
                          </Grid>
                        )}

                        <Captcha id="sample-captcha" captchaMessage={captchaMessage} />

                        <Grid item xs={12}>
                          <Button type="submit" disabled={mailSendActivated}>
                            {t[language].help.button_label}
                          </Button>
                          {sendError && (
                            <FormHelperText error>
                              <Icon
                                icon="exclamation-circle"
                                iconDSFR="error-warning-line"
                                title={t[language].help.error}
                              />
                              &nbsp;{sendError}
                            </FormHelperText>
                          )}
                          {mailSendActivated && (
                            <FormHelperText error>
                              <Icon
                                icon="exclamation-circle"
                                iconDSFR="error-warning-line"
                                title={t[language].help.error}
                              />
                              &nbsp;{t[language].help.send_mail_error_message}
                            </FormHelperText>
                          )}
                        </Grid>
                        <div className={classes.requiredFields}>
                          * {t[language].common.required_fields[getTranslateIndex(2)]}
                        </div>
                      </Grid>
                    </form>
                  )}
                  {formSended && form.subject && !form.subject.autoReply && (
                    <div className={classes.formValidated}>
                      <Box pb={3}>
                        <Icon
                          icon="check-circle"
                          type="fas"
                          iconDSFR="checkbox-circle-fill"
                          className={classes.formValidatedIcon}
                          title={t[language].help.message_sent_title}
                        />
                      </Box>
                      <Box pb={1.5}>
                        <h5>{t[language].help.message_sent_subtitle}</h5>
                      </Box>
                      <Box pb={3}>
                        <p style={{ maxWidth: 250, lineHeight: "22px" }} role="presentation">
                          {t[language].help.message_sent_description}
                        </p>
                      </Box>
                      <Button outlined onClick={handleBackToForm}>
                        {t[language].help.back_button_label}
                      </Button>
                    </div>
                  )}

                  {formSended && form.subject && form.subject.autoReply && (
                    <div style={{ width: "100%" }}>
                      <Box pb={2.5}>
                        <h5>{t[language].help.auto_reply_title}</h5>
                      </Box>
                      <Box pb={2.5}>
                        <b>{t[language].help.auto_reply_object_prefix} : </b>
                        {form.subject.title}
                      </Box>
                      <hr />
                      <Box pb={2.5}>
                        {t[language].help.auto_reply_body1}{" "}
                        <b>
                          {form.firstName} {form.name}
                        </b>
                      </Box>
                      <Box pb={2.5}>{form.subject.replyMessage}</Box>
                      <Box pb={2.5}>
                        <div>{t[language].help.auto_reply_body2}</div>
                      </Box>
                      <Button outlined onClick={handleBackToForm}>
                        {t[language].help.back_button_label}
                      </Button>
                    </div>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item xs={12} container lg={expandedContactModeItem ? 6 : true}>
                  <Grid item container spacing={2} className={classes.contactModeWrapper}>
                    {modes.map(
                      (mode) =>
                        mode.title && (
                          <Grid item xs={12 / modes.length} lg={12} className={classes.contactModeItem} key={mode.id}>
                            <div
                              className={classes.contactMode}
                              onClick={() => !isContactModeClickDisabled && handleContactModeClick(mode)}
                            >
                              <Hidden lgUp>
                                <div>
                                  {mode.icons.map((icon) => (
                                    <Icon
                                      className={classes.contactModeIcon}
                                      {...icon}
                                      key={icon.icon}
                                      title={mode.title}
                                    />
                                  ))}
                                </div>
                              </Hidden>
                              <div style={{ flex: "1 1 0px" }}>
                                <p
                                  className={classnames(classes.contactModeTitle, isMobile ? "" : "fr-h2")}
                                  onKeyDown={(e) => handleKeyDown(e, mode)}
                                  role="presentation"
                                >
                                  {mode.title}
                                </p>
                                <Hidden mdDown>
                                  <p>{mode.subtitle}</p>
                                </Hidden>
                              </div>
                              <div className={classes.contactModeArrow}>
                                {!isContactModeClickDisabled && (
                                  <Icon
                                    icon={isOpen(contactForm) ? "chevron-left" : "chevron-right"}
                                    iconDSFR={isOpen(mode) ? "arrow-up-s-line" : "arrow-down-s-line"}
                                    className={classes.contactModeArrowIcon}
                                    tabIndex={0}
                                    onKeyDown={(e) => handleKeyDown(e, mode)}
                                    isHidden={false}
                                    aria-label={
                                      isOpen(contactForm) ? t[language].common.close : t[language].common.open
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </Grid>
                        )
                    )}
                  </Grid>
                </Grid>

                {contactPhoneTitle && isOpen(contactPhone) && (
                  <Grid item xs lg={6} container>
                    <Grid
                      item
                      className={classes.contactModeDetail}
                      container
                      direction="column"
                      justifyContent="center"
                    >
                      <Hidden lgUp>
                        <div className={classes.contactModeDetailElement}>{contactPhoneSubtitle}</div>
                      </Hidden>
                      {phone1?.props?.children && (
                        <Grid item className={classnames(classes.phoneNumber, classes.contactModeDetailElement)}>
                          <Icon
                            icon="phone"
                            iconDSFR="phone-line"
                            className={classes.phoneIcon}
                            title={t[language].help.icon_phone_title}
                          />
                          {phone1}
                        </Grid>
                      )}
                      {phone2?.props?.children && (
                        <Grid item className={classnames(classes.phoneNumber, classes.contactModeDetailElement)}>
                          <Icon
                            icon="phone"
                            iconDSFR="phone-line"
                            className={classes.phoneIcon}
                            title={t[language].help.icon_phone_title}
                          />
                          {phone2}
                        </Grid>
                      )}
                      {phone3?.props?.children && (
                        <Grid item className={classnames(classes.phoneNumber, classes.contactModeDetailElement)}>
                          <Icon icon="deaf" className={classes.phoneIcon} title={t[language].common.deaf_icon_title} />
                          <div style={{ width: "100%" }}>
                            <div className={classes.contactModeDetailElement}>{phone3}</div>
                            <div className={classes.contactModeDetailElement}>
                              <div onClick={handleClickDeafAccess}>{deafAccess}</div>
                            </div>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                {contactDigitalPointsTitle && isOpen(contactDigitalPoints) && (
                  <Grid item xs lg={6} container>
                    <Grid
                      item
                      className={classes.contactModeDetail}
                      container
                      direction="column"
                      justifyContent="center"
                    >
                      <Grid item className={classes.contactModeDetailElement}>
                        {text}
                      </Grid>
                      <Grid item className={classes.contactModeDetailElement}>
                        <div onClick={handleClickDigitalPoint}>{digitalPoint}</div>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Block>
      </Wrapper>
    </Fragment>
  );
};

HelpForm.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default HelpForm;
