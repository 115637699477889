import { stringToSnakeCaseString } from "utils/commonUtils";
import CookiesConsentService from "services/CookiesConsentService";

const convertInfoTagValues = (infoTag) => {
  return Object.keys(infoTag).reduce((newObj, key) => {
    const info = { ...newObj };
    info[key] = typeof infoTag[key] === "string" ? stringToSnakeCaseString(infoTag[key]) : infoTag[key];
    return info;
  }, {});
};

const newTag = (options) => {
  try {
    return new window.ATInternet.Tracker.Tag(options);
  } catch (ex) {
    return {
      dispatch: () => ({}),
      page: { send: () => ({}) },
      click: { send: () => ({}) },
    };
  }
};

const getSiteName = (s) => {
  switch (s) {
    case "ANTS":
      return "chapeau";
    case "PASSEPORT_CNI":
      return "Carte d'identité et Passeport";
    case "IMMAT":
      return "Immatriculation";
    case "PERMIS":
      return "Permis de conduire";
    case "PHA":
      return "Professionnel de l'automobile";
    case "AUTO_ECOLE":
      return "Auto-Ecole et CSSR";
    case "MAIRIE":
      return "Mairie";
    default:
      return "";
  }
};

class ATInternetService {
  constructor() {
    this.disableCookie = true;
    this.tag = newTag({ secure: true, disableCookie: this.disableCookie });
    this.site = {};
    this.sendPage = this.sendPage.bind(this);
    this.sendClick = this.sendClick.bind(this);
    this.setSite = this.setSite.bind(this);
    this.getChapter1 = this.getChapter1.bind(this);
    this.checkCookieConsent = this.checkCookieConsent.bind(this);
  }

  checkCookieConsent() {
    if (this.tag.privacy) {
      const consent = CookiesConsentService.getConsent();
      if (consent === "true") {
        this.disableCookie = false;
        this.tag.privacy.setVisitorOptin();
        this.tag = newTag({ secure: true });
      } else if (consent === "false") {
        this.disableCookie = false;
        this.tag.privacy.setVisitorOptout();
      } else {
        this.disableCookie = false;
        this.tag.privacy.setVisitorMode("cnil", "exempt"); // Visiteur défini en Exempt
        this.tag = newTag({ secure: true });
      }
    }
  }

  setSite(site) {
    this.site = site;
  }

  getChapter1() {
    return getSiteName(this.site.name);
  }

  // @param info: {name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, customObject?: any}
  sendPage(info) {
    this.checkCookieConsent();
    const pageTag = { ...info, chapter1: this.getChapter1() };
    this.tag.page.send(convertInfoTagValues(pageTag));
  }

  // @param info: {elem: any, name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, type: string, customObject?: any}
  sendClick(info) {
    this.checkCookieConsent();
    const clickTag = { ...info, chapter1: this.getChapter1() };
    this.tag.click.send(convertInfoTagValues(clickTag));
  }
}
export default new ATInternetService();
