import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Block from "components/templatesComponents/Block";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import { makeStyles } from "@material-ui/styles";
import CardContext from "components/templatesComponents/CardContext";
import HorizontalScroll from "components/templatesComponents/HorizontalScroll";
import SeeMoreLink from "components/templatesComponents/SeeMoreLink";
import Wrapper from "components/LayoutFront/Wrapper";
import Grid from "@material-ui/core/Grid";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  horizontalContainer: {
    [theme.breakpoints.up("lg")]: {
      margin: "0 -76px",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(5),
    },
  },
  background: {
    backgroundColor: theme.palette.primary[10],
  },
  item: {
    display: "flex",
    width: "222px",
    height: "216px",
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      paddingRight: theme.spacing(3),
    },
    "&:last-child": {
      paddingRight: 0,
    },
  },
  title: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
  seeAll: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    alignSelf: "flex-end",
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(3),
    },
  },
}));
const HomeServices = (props) => {
  const { page } = props;
  const { title, contents } = page;
  const { pageLinks } = contents;

  const { language } = useContext(LanguageContext);

  const classes = useStyles();
  const cards = ((pageLinks && pageLinks.props && pageLinks.props.cards) || []).map((card) => (
    <CardContext.Provider
      value={{
        desktopStyleKey: styleKeys.WITH_ICON_LITTLE,
        mobileStyleKey: styleKeys.WITH_ICON_LITTLE,
      }}
      key={card.key}
    >
      {card}
    </CardContext.Provider>
  ));
  return (
    <Wrapper
      className={classnames(classes.background, classes.root)}
      leftProps={{ className: classes.background }}
      rightProps={{ className: classes.background }}
    >
      <Block>
        <h2 className={classnames(classes.title, "fr-h1")}>{title}</h2>
        <div className={classes.horizontalContainer}>
          <HorizontalScroll items={cards} itemClass={classes.item} />
        </div>
        <Grid container justifyContent="flex-end">
          <SeeMoreLink page={page} className={classes.seeAll}>
            {t[language].home_services.see_all} {title.toLowerCase()}
          </SeeMoreLink>
        </Grid>
      </Block>
    </Wrapper>
  );
};
HomeServices.propTypes = {
  page: PropTypes.shape(),
};
HomeServices.defaultProps = {
  page: {},
};
export default HomeServices;
