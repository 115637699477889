import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import elementsTypes from "utils/elementsTypes";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import CardContext from "components/templatesComponents/CardContext";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import {
  Context as GroupContext,
  defaultContext as defaultGroupContext,
} from "components/adminComponents/AdminContentsGroups";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  hiddenDesktop: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  hiddenMobile: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "inherit",
    },
  },
  subtitle: {
    margin: theme.spacing(1, 0, 3, 0),
  },
  box: {
    boxSizing: "content-box",
  },
}));

const Processes = (props) => {
  const { page } = props;
  const { title, contents } = page;
  const { cardsGroups, cardZero, cardOne, cardTwo, cardThree, cardFour, subtitle, otherCards, otherProcesses } =
    contents;

  const theme = useTheme();
  const classes = useStyles();

  const allProcesses = (cardZero && cardZero.props && cardZero.props.link && cardZero.props.link.url) || false;

  const getCardLabelFromContent = (content) => {
    const { children = [] } = content || {};
    const titleChild = children.find((c) => c.key === "title") || {};
    return titleChild.value;
  };

  const getCardLabelFromComponent = (card) => card && card.props && card.props.title;

  const renderCardOneToThree = (className) => (
    <Fragment>
      {cardOne && (
        <Grid item xs={6} className={className}>
          <CardContext.Provider
            value={{
              desktopStyleOverride: {
                root: {
                  backgroundColor: theme.palette.primary.tones[allProcesses ? 0 : 1],
                  "a&:hover": {
                    backgroundColor: theme.palette.primary.tones[allProcesses ? 0 : 1],
                  },
                },
              },
            }}
          >
            {cardOne}
          </CardContext.Provider>
        </Grid>
      )}
      {cardTwo && (
        <Grid item xs={6} className={className}>
          <CardContext.Provider
            value={{
              desktopStyleOverride: {
                root: {
                  backgroundColor: theme.palette.primary.tones[allProcesses ? 1 : 2],
                  "a&:hover": {
                    backgroundColor: theme.palette.primary.tones[allProcesses ? 1 : 2],
                  },
                },
              },
            }}
          >
            {cardTwo}
          </CardContext.Provider>
        </Grid>
      )}
      {cardThree && (
        <Grid item xs={6} lg={12} className={className}>
          <CardContext.Provider
            value={{
              desktopStyleOverride: {
                root: {
                  backgroundColor: theme.palette.primary.tones[allProcesses ? 2 : 0],
                  "a&:hover": {
                    backgroundColor: theme.palette.primary.tones[allProcesses ? 2 : 0],
                  },
                },
              },
            }}
          >
            {cardThree}
          </CardContext.Provider>
        </Grid>
      )}
    </Fragment>
  );

  return (
    <Fragment>
      <Wrapper>
        <GroupContext.Provider
          value={{
            ...defaultGroupContext,
            buttonLabel: "Gérer des groupes de cards",
            title: "Gérer des groupes de cards",
            subTitle:
              "Ces groupes sont utilisés dans le résumé de page lorsque celle-ci est ajoutée à la page d'accueil",
            types: elementsTypes.CARD,
            getLabelFromComponent: getCardLabelFromComponent,
            getLabelFromContent: getCardLabelFromContent,
          }}
        >
          {React.isValidElement(cardsGroups) && cardsGroups}
        </GroupContext.Provider>
        <Block>
          <PageTitle>{title}</PageTitle>
        </Block>
      </Wrapper>
      <Wrapper>
        <Block>
          <Grid container spacing={2}>
            {cardZero && (
              <Grid item xs={6} lg={4}>
                <CardContext.Provider
                  value={{
                    desktopStyleKey: allProcesses ? styleKeys.BIG : styleKeys.VERTICAL_BIG,
                    mobileStyleKey: allProcesses ? styleKeys.LITTLE_COLOR : styleKeys.LITTLE_LIGHT,
                  }}
                >
                  {cardZero}
                </CardContext.Provider>
              </Grid>
            )}
            {renderCardOneToThree(classes.hiddenDesktop)}
            <Grid item xs={8} container spacing={2} className={classes.box}>
              {renderCardOneToThree(classes.hiddenMobile)}
            </Grid>
            {cardFour && (
              <Grid item xs={6} lg={8}>
                <CardContext.Provider
                  value={{
                    desktopStyleOverride: {
                      root: {
                        backgroundColor: theme.palette.primary.tones[allProcesses ? 0 : 1],
                        "a&:hover": {
                          backgroundColor: theme.palette.primary.tones[allProcesses ? 0 : 1],
                        },
                      },
                    },
                  }}
                >
                  {cardFour}
                </CardContext.Provider>
              </Grid>
            )}
            <CardContext.Provider
              value={{
                Wrapper: Grid,
                wrapperProps: {
                  item: true,
                  xs: 6,
                  md: 4,
                },
                camaieuStart: allProcesses ? 1 : 2,
              }}
            >
              {otherCards}
            </CardContext.Provider>
          </Grid>
        </Block>
      </Wrapper>
      {otherProcesses && (
        <Block>
          <Wrapper>
            <h2 className={classes.subtitle}>{subtitle}</h2>
          </Wrapper>
          <Wrapper>
            <Grid container alignItems="stretch" spacing={2}>
              <CardContext.Provider
                value={{
                  Wrapper: Grid,
                  wrapperProps: {
                    item: true,
                    xs: 6,
                    md: 3,
                  },
                  desktopStyleOverride: {
                    root: {
                      backgroundColor: theme.palette.primary.tones[0],
                      "a&:hover": {
                        backgroundColor: theme.palette.primary.tones[0],
                      },
                    },
                  },
                }}
              >
                {otherProcesses}
              </CardContext.Provider>
            </Grid>
          </Wrapper>
        </Block>
      )}
    </Fragment>
  );
};

Processes.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Processes;
