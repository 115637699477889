import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Wrapper from "components/LayoutFront/Wrapper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Block from "components/templatesComponents/Block";
import CardVertical from "components/templatesComponents/CardVertical";
import TagsComponent from "components/templatesComponents/Tags";
import { ImageContainer } from "components/templatesComponents/Image";
import Share from "components/LayoutFront/Share";
import { useHistory } from "react-router";
import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import PageService from "services/PageService";
import allNews from "components/templates/news/allNews";
import useAxiosCache from "hooks/axiosCache";
import { formatDate } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  main: {
    margin: theme.spacing(2, 0, 2, 0),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2, 1, 2, 1),
    },
  },
  wrapperProps: {
    position: "relative",
    paddingBottom: theme.spacing(8),
    maxWidth: "1440px",
    margin: "0 auto",
  },
  blockTitle: {
    paddingBottom: 0,
  },
  shortDescription: {
    margin: theme.spacing(0, 2, 3, 2),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 0, 3, 0),
    },
  },
  informationsContainer: {
    padding: theme.spacing(1, 2, 2, 2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2, 0, 2, 0),
    },
  },
  rightContainer: {
    position: "sticky",
    top: "32px",
    margin: theme.spacing(8, 2, 0, 2),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0),
    },
  },
  tags: {
    margin: theme.spacing(2.5, 0),
  },
  mobileTags: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopTags: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  title: {
    margin: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  imageCaption: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "auto",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  divider: {
    margin: theme.spacing(0, 2),
    [theme.breakpoints.up("md")]: {
      margin: "0",
    },
  },
  dynamicPart: {
    margin: theme.spacing(4, 0, 0, 0),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(5),
    },
  },
  firstPublishedDate: {
    margin: theme.spacing(0.5, 3.5),
    color: "#666666",
  },
  noArticle: {
    textAlign: "center",
    fontStyle: "italic",
  },
}));

const NewsTemplate = (props) => {
  const { page: pageVersion } = props;

  const site = useContext(SiteContext);
  const { language } = useContext(LanguageContext);
  const { name: siteName } = site;

  const { id, title, shortDescription, image, contents, tags, publishedDate, page } = pageVersion;
  const { firstPublishedDate, parentId, lang } = page;
  const { watchTime, ratio, imageCaption, dynamicPart, dynamicAttachments } = contents;

  const classes = useStyles();

  const [{ data: allNewsData = [] }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: [allNews.key],
      siteName,
      filters: [`page.lang||eq||${lang}`],
    })
  );

  const [{ data = [] }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: ["NEWS"],
      siteName,
      filters: [`id||ne||${id}`, `page.lang||eq||${lang}`],
    })
  );

  const allNewsPages = allNewsData?.pages || allNewsData;

  const newsPages = data?.pages || data;

  const sameThemePages = React.useMemo(() => {
    const newPages = newsPages.map((p) => {
      const tagNumber =
        p.tags?.reduce((a, b) => (tags?.map((tag) => tag.toLowerCase())?.includes(b.toLowerCase()) ? a + 1 : a), 0) ||
        0;
      return { ...p, tagNumber };
    });
    return newPages
      .sort((newPage1, newPage2) => newPage2.tagNumber - newPage1.tagNumber)
      .filter((stp) => stp.tagNumber > 0)
      .slice(0, 3);
  }, [newsPages, tags]);

  const Tags = () => {
    const history = useHistory();

    const goToAllNewsWithTag = (tag) => {
      const allNewsPath = allNewsPages?.find((p) => p.pageId === parentId)?.fullPath || allNewsPages[0]?.fullPath;

      if (allNewsPages) {
        const defaultSelectedTag = tag;

        history.push(allNewsPath, {
          defaultSelectedTag,
        });
      }
    };

    return (
      tags && (
        <Block>
          <TagsComponent tags={tags} withDSFR handleSelectTags={goToAllNewsWithTag} />
        </Block>
      )
    );
  };

  return (
    <Wrapper breakpoint="xl" wrapperProps={{ className: classes.wrapperProps }}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={7}>
          <Box className={classes.title}>
            <h1>{title}</h1>
          </Box>
          {shortDescription && <Box className={classes.shortDescription}>{shortDescription}</Box>}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={7}>
          <hr className={classes.divider} />
          <Grid container className={classes.informationsContainer} justifyContent="space-between">
            <Grid item xs={10} sm={8} md={10} lg={7} xl={8} container alignItems="center">
              <Grid item>
                {publishedDate && (
                  <p className="fr-text--xs" role="presentation">
                    <Box component="span" className="fr-icon-calendar-event-line" aria-hidden="true" />
                    {` `}
                    {t[language].common.news.updated_label}{" "}
                    {formatDate({ date: publishedDate, lang: `${lang}-${lang.toLowerCase()}` })}
                  </p>
                )}
              </Grid>
              {firstPublishedDate && (
                <Grid item style={{ marginLeft: "26px" }}>
                  <p className="fr-text--xs" role="presentation">
                    {t[language].news.first_published}{" "}
                    {formatDate({ date: firstPublishedDate, lang: `${lang}-${lang.toLowerCase()}` })}
                  </p>
                </Grid>
              )}
            </Grid>
            {watchTime && (
              <Grid item xs="auto">
                <p className="fr-text--xs" role="presentation">
                  <Box component="span" className="fr-icon-timer-line" aria-hidden="true" />
                  {` `}
                  {t[language].news.read_label} : {watchTime}
                </p>
              </Grid>
            )}
          </Grid>
          {image && (
            <>
              {typeof ratio?.props?.content === "object" && (
                <Grid container justifyContent="center" spacing={2} style={{ marginBottom: "8px" }}>
                  <Grid item>Ratio d&apos;image :</Grid>
                  <Grid item xs>
                    {ratio}
                  </Grid>
                </Grid>
              )}
              <ImageContainer
                ratio={
                  (typeof ratio?.props?.content === "object" ? Number(ratio.props?.content.value) : Number(ratio)) || 52
                }
              >
                {image}
              </ImageContainer>
              {imageCaption && (
                <figcaption className={`${classes.imageCaption} fr-content-media__caption`}>{imageCaption}</figcaption>
              )}
            </>
          )}
          {dynamicPart && <Box className={classes.dynamicPart}>{dynamicPart}</Box>}
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={classes.rightContainer}>
            {dynamicAttachments && (
              <Box>
                <Box component="p" className="fr-text--bold" mb={2}>
                  {t[language].news.pj_title}
                </Box>
                {dynamicAttachments}
              </Box>
            )}
            <Share forceDisplay withTitle />
          </Box>
        </Grid>
      </Grid>
      {tags && (
        <>
          <Box component="p" className="fr-h5" mt={7.5} sx={{ mb: 0.5, px: { xs: 2, md: 0 } }}>
            {t[language].news.same_theme_title}
          </Box>
          <Tags tags={tags} />
          <Block>
            {sameThemePages.length > 0 ? (
              <Grid container spacing={2}>
                {sameThemePages.map((p) => (
                  <Grid item xs={12} md={4} key={p.id}>
                    <CardVertical page={p} noImage />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <p className={classes.noArticle} role="presentation">
                {t[language].news.no_article_message}
              </p>
            )}
          </Block>
        </>
      )}
    </Wrapper>
  );
};

NewsTemplate.propTypes = {
  page: PropTypes.shape(),
};

NewsTemplate.defaultProps = {
  page: {},
};

export default NewsTemplate;
