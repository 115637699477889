import KnowEverything from "components/templates/knowEverything/KnowEverything";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "KNOW_EVERYTHING",
  label: "L - Tout savoir",
  Component: KnowEverything,
  servesOtherPages: true,
  initialContents: [
    {
      key: "topNews",
      type: elementsTypes.SUBPAGES_LIST,
      value: "",
      children: [
        {
          key: "limit",
          value: "1",
        },
      ],
    },
    {
      key: "highlighted",
      type: elementsTypes.SUBPAGES_LIST,
      value: "",
      children: [
        {
          key: "limit",
          value: "1",
        },
      ],
    },
    {
      key: "allNewsTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Tous les articles",
    },
    {
      key: "allNews",
      type: elementsTypes.SUBPAGES_LIST,
      value: "",
      children: [],
    },
    {
      key: "regulationsTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Toute la réglementation",
    },
    {
      key: "regulations",
      value: "",
      children: [
        {
          key: "title",
          type: elementsTypes.EDITABLE_INPUT,
          value: "Les décrets",
        },
        {
          key: "decrees",
          type: elementsTypes.ACCORDION,
          value: "",
          children: [],
        },
      ],
    },

    {
      key: "regulations",
      value: "",
      children: [
        {
          key: "title",
          type: elementsTypes.EDITABLE_INPUT,
          value: "Les arrêtés",
        },
        {
          key: "decrees",
          type: elementsTypes.ACCORDION,
          value: "",
          children: [],
        },
      ],
    },
  ],
};
