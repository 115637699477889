import React, { useState, useContext, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Wrapper from "components/LayoutFront/Wrapper";
import { getQueryParams, createQueryParams } from "utils/urlUtils";
import wrappable from "utils/wrappable";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ATInternetService from "services/ATInternetService";
import EulerianService from "services/EulerianService";
import PageVersionContext from "components/PageVersionContext";

const TabPanel = wrappable((props) => {
  const { children, index, hidden, ...others } = props;

  return (
    <Box
      role="tabpanel"
      hidden={hidden}
      {...others}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {children}
    </Box>
  );
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
TabPanel.defaultProps = {
  children: null,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    "& .customTabsScrollable": {
      overflowX: "auto",
    },
  },
  indicator: {
    "&:before": {
      content: `""`,
      display: "block",
      height: "100%",
      width: "30%",
      margin: "0 auto",
    },
  },
  panel: {
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(4),
    },
  },
  tab: {
    textTransform: "none",
    minWidth: 0,
    padding: 0,
    marginRight: theme.spacing(3),
    fontSize: "0.875rem",
    lineHeight: "1.375rem",
    "a&:hover": {
      backgroundColor: "#e0e0e080",
    },
    "&.Mui-focusVisible": {
      border: "2px solid #0a76f6",
    },
  },
  tabs: {
    borderBottom: "1px solid #E5E5E5",
    margin: theme.spacing(0, 2),
  },
  selected: {
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  horizontalContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      margin: `${theme.spacing(4)}px -40px 0 -40px`,
    },
  },
}));

const ScrollableTabs = wrappable((props) => {
  const {
    location,
    history,
    tabs = [],
    tabsProps,
    defaultTabKey,
    identifier,
    variant,
    scrollButtons,
    panelClass,
    navigate,
    sendTag,
    setSendTag,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const { currentPageVersion } = useContext(PageVersionContext);
  const { breadcrumb = [] } = currentPageVersion || {};

  const getTabKey = useCallback((tab = {}) => tab.url || `${tabs.indexOf(tab)}`, [tabs]);

  const getCurrentTab = useCallback(() => {
    if (navigate) {
      const key = getQueryParams(location.search)[identifier];
      if (!tabs.find((tab) => getTabKey(tab) === key)) {
        if (defaultTabKey && tabs.find((tab) => getTabKey(tab) === defaultTabKey)) {
          return defaultTabKey;
        }
        return getTabKey(tabs[0]);
      }
      return key;
    }
    return getTabKey(tabs[0]);
  }, [location.search, getTabKey, defaultTabKey, identifier, tabs, navigate]);

  const [currentTab, setCurrentTab] = useState(getCurrentTab());

  useEffect(() => {
    if (navigate) {
      setCurrentTab(getCurrentTab());
    }
  }, [getCurrentTab, navigate]);

  useEffect(() => {
    if (currentTab && breadcrumb) {
      if (sendTag) {
        const tabActive = tabs.find((tab) => currentTab === getTabKey(tab));
        const pageTag = {
          name: tabActive?.title,
        };
        Object.keys(breadcrumb).forEach((key) => {
          if (key > 0) {
            pageTag[`chapter${Number(key) + 1}`] = breadcrumb[key]?.title;
          }
        });
        ATInternetService.sendPage(pageTag);
        const infoTag = {
          page_name: tabActive?.title,
        };
        Object.keys(breadcrumb).forEach((key) => {
          if (key > 0) {
            infoTag[`page_category${Number(key) + 1}`] = breadcrumb[key]?.title;
          }
        });
        EulerianService.sendPage(infoTag);
      } else {
        setSendTag(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const handleChange = (event, newTabKey) => {
    if (navigate) {
      const queryParams = getQueryParams(location.search);
      history.push({
        pathname: location.pathname,
        search: `?${createQueryParams({
          ...queryParams,
          [identifier]: newTabKey,
        })}`,
        state: { prevPath: location.pathname + location.search },
      });
    } else {
      setCurrentTab(newTabKey);
    }
  };

  if (typeof document !== "undefined") {
    const tabsScrollable = document.getElementsByClassName("MuiTabs-scrollable");
    if (tabsScrollable) {
      for (let i = 0; i < tabsScrollable.length; i += 1) {
        if (tabsScrollable[i] && tabsScrollable[i].classList && tabsScrollable[i].classList.value) {
          tabsScrollable[i].classList.value = tabsScrollable[i].classList.value.replace(
            "MuiTabs-scrollable",
            "customTabsScrollable"
          );
        }
      }
    }
  }

  return (
    <div className={classes.root}>
      <Wrapper>
        <div className={scrollButtons === "on" ? classes.horizontalContainer : undefined}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            variant={variant || "standard"}
            selectionFollowsFocus
            scrollButtons={isMobile ? "auto" : scrollButtons || "auto"}
            TabIndicatorProps={{ className: classes.indicator }}
          >
            {tabs.map((tab) => (
              <Tab
                key={getTabKey(tab)}
                label={tab.title}
                className={classes.tab}
                value={getTabKey(tab)}
                disabled={tab.disabled}
                {...tabsProps}
                {...a11yProps(getTabKey(tab))}
              />
            ))}
          </Tabs>
        </div>
      </Wrapper>
      {tabs.map((tab) => {
        const { url, ...othersTabProps } = tab;
        return (
          <TabPanel
            {...othersTabProps}
            key={getTabKey(tab)}
            index={getTabKey(tab)}
            hidden={currentTab !== getTabKey(tab)}
            className={panelClass || classes.panel}
          >
            {tab.children}
          </TabPanel>
        );
      })}
    </div>
  );
});

ScrollableTabs.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      children: PropTypes.node,
    })
  ),
  scrollButtons: PropTypes.string,
  defaultTabKey: PropTypes.string,
  panelClass: PropTypes.string,
  identifier: PropTypes.string,
  variant: PropTypes.string,
  navigate: PropTypes.bool,
  sendTag: PropTypes.bool,
  setSendTag: PropTypes.func,
};

ScrollableTabs.defaultProps = {
  tabs: [],
  defaultTabKey: null,
  identifier: "t",
  variant: null,
  panelClass: null,
  scrollButtons: null,
  navigate: true,
  sendTag: true,
  setSendTag: null,
};

export default withRouter(ScrollableTabs);
