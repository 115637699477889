import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { createQueryParams } from "utils/urlUtils";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Autosuggest from "components/templatesComponents/Autosuggest";
import PageService from "services/PageService";
import Link from "components/templatesComponents/Link";
import MessageContext from "components/MessageContext";
import LanguageContext from "components/LanguageContext";
import { eventKey } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "white",
    border: `1px solid ${theme.palette.componentColors[30]}`,
    padding: "0 16px",
    height: "56px",
    fontSize: "1em",
    "&.Mui-focused": {
      borderColor: theme.palette.primary.main,
    },
    "& input": {
      height: "1.4rem",
    },
    "& button": {
      marginRight: "-12px",
      color: theme.palette.componentColors[70],
    },
  },
  searchBar: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: "22rem",
    marginRight: theme.spacing(3),
    "& div": {
      width: "15.75rem",
    },
  },
}));
const useAutoSuggestStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    position: "relative",
    padding: 0,
  },
  suggestionsContainer: {
    position: "absolute",
    left: 14,
    right: 14,
    textAlign: "initial",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.componentColors[30]}`,
    borderBottom: "none",
    borderTop: "none",
    zIndex: 100,
    [theme.breakpoints.up("lg")]: {
      left: "auto",
      right: "24px",
      width: "15.75rem",
    },
  },
  suggestionsList: {
    listStyleType: "none",
    paddingLeft: "0 !important",
    margin: 0,
  },
  suggestion: {
    "&:hover, &[aria-selected=true]": {
      backgroundColor: theme.palette.componentColors[30],
    },
    "&:last-child": {
      borderBottom: `1px solid ${theme.palette.componentColors[30]}`,
    },
  },
}));

const SearchBar = (props) => {
  const { onSearch } = props;

  const classes = useStyles();
  const autoSuggestClasses = useAutoSuggestStyles();

  const { pathname, search } = useLocation();
  const history = useHistory();
  const [value, setValue] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { displayError } = useContext(MessageContext);
  const { language } = useContext(LanguageContext);

  const goToSearchPage = () => {
    if (value) {
      onSearch();
      history.push({
        pathname: `/rechercher`,
        search: `?${createQueryParams({ q: value })}`,
        state: { prevPath: pathname + search },
      });
    }
  };

  const handleIconClick = () => {
    goToSearchPage();
  };

  const handleKeyDown = (e) => {
    if (e.key === eventKey) {
      goToSearchPage();
    }
  };

  const getSuggestions = async (newValue) => {
    try {
      const sugg = await PageService.search({ query: newValue, lang: language, from: 0, size: 5 });
      const { hits } = sugg || {};
      const { hits: results } = hits || {};
      return (results && Array.isArray(results) && results.map((res) => res._source)) || [];
    } catch (e) {
      displayError(
        "Le service de recherche est actuellement indisponible. Veuillez nous excuser pour la gêne occasionnée."
      );
      return [];
    }
  };

  const getSuggestionValue = (suggestion) => suggestion.title;

  const renderSuggestion = (suggestion) => {
    let breadcrumb = suggestion.breadcrumb.map((b) => b.title);
    if (breadcrumb && breadcrumb.length > 3) {
      breadcrumb = [breadcrumb[0], breadcrumb[1], "...", breadcrumb[breadcrumb.length - 1]];
    }
    const subtitle = breadcrumb.join(` / `);
    return (
      <Link page={suggestion}>
        <div style={{ padding: theme.spacing(1.5, 2) }}>
          <b>{suggestion.title}</b>
          <p className="fr-text--xs">{subtitle}</p>
        </div>
      </Link>
    );
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionSelected = () => {
    onSearch();
    setValue("");
  };

  const renderInputComponent = (inputProps) => (
    <div
      aria-expanded="false"
      className={classnames("fr-container fr-container-lg--fluid", isMobile ? undefined : classes.searchBar)}
      style={isMobile ? undefined : { maxWidth: "22rem", marginRight: theme.spacing(3) }}
    >
      {isMobile && (
        <button type="button" className="fr-link--close fr-link" aria-controls="modal-866" onClick={onSearch}>
          {t[language].common.close}
        </button>
      )}
      <div className="fr-search-bar" id="search-865" role="search">
        <label className="fr-label" htmlFor="search-865-input">
          {t[language].common.search.label}
        </label>
        <input
          className="fr-input"
          type="search"
          id="search-865-input"
          name="search-865-input"
          placeholder={t[language].common.search.placeholder}
          {...inputProps}
        />
        <button
          type="button"
          className="fr-btn"
          title={t[language].common.search.placeholder}
          onClick={handleIconClick}
        >
          {t[language].common.search.placeholder}
        </button>
      </div>
    </div>
  );

  const inputProps = {
    value,
    onChange,
    onKeyDown: handleKeyDown,
  };

  // Accessibilité: remove un role="listbox" non pertinent
  if (typeof document !== "undefined") {
    const suggestContainer = document.getElementById("react-autowhatever-1");
    if (suggestContainer) {
      suggestContainer.removeAttribute("role");
    }
  }

  return (
    <Autosuggest
      getSuggestions={getSuggestions}
      debounce={300}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      classes={autoSuggestClasses}
      noResult={
        <div>
          <b>
            {t[language].common.search.no_result_message} : &laquo; {value} &raquo;
          </b>
        </div>
      }
    />
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func,
};

SearchBar.defaultProps = {
  onSearch: () => null,
};

export default SearchBar;
