import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PageTitle from "components/templatesComponents/PageTitle";

const Standard = (props) => {
  const { page } = props;
  const { title, shortDescription, contents } = page;
  const { infoBlocks, dynamicPart } = contents;
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Wrapper>
      <Grid container spacing={isMobile ? 0 : 2}>
        <Grid item xs>
          <Block>
            <PageTitle>{title}</PageTitle>
          </Block>
        </Grid>
        {infoBlocks && (
          <Grid item lg={6} xs={12}>
            <Block>{infoBlocks}</Block>
          </Grid>
        )}
      </Grid>
      {shortDescription && (
        <Block>
          <Grid container>
            <Grid item xs={12} lg={8}>
              <p>{shortDescription}</p>
            </Grid>
          </Grid>
        </Block>
      )}
      {dynamicPart}
    </Wrapper>
  );
};

Standard.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Standard;
