import unorm from "unorm";

let timer;
// eslint-disable-next-line import/prefer-default-export
export const debounce = (callback, delay) => {
  // eslint-disable-next-line func-names
  return function () {
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(context, args);
    }, delay);
  };
};

export const eventKey = "Enter";

export const frenchNameRegex = /[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/g;
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
export const immatSivRegex = /^(W|[A-Z]{2})[-][0-9]{3}[-][A-Z]{2}$/g;
export const immatCycloRegex = /^[A-Z]{1,2}[ ][0-9]{2,3}[ ][A-Z]$/g;
export const permisRegex = /^\w{1,15}$/g;
export const latitudeRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/g;
export const longitudeRegex = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g;

export const checkFrenchNameRules = ({ key, length }) => !!(key && key.match(frenchNameRegex) && key.length <= length);
export const checkEmailRules = ({ key }) => !!(key && key.match(emailRegex) && key.length <= 80);
export const checkMessageLength = ({ key }) => !(!key || key.length > 2000);

export const formatDate = ({ date, lang }) =>
  new Date(date).toLocaleDateString(lang, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

export const getTranslateIndex = (value) => (value > 1 ? 1 : 0);

export const getSiteName = (s) => {
  switch (s) {
    case "ANTS":
      return "ANTS";
    case "PASSEPORT_CNI":
      return "Carte d'identité et passeport";
    case "IMMAT":
      return "Immatriculation";
    case "PERMIS":
      return "Permis de conduire";
    case "PHA":
      return "Professionnel de l'automobile";
    case "AUTO_ECOLE":
      return "Auto-Ecole et CSSR";
    case "MAIRIE":
      return "Mairie";
    default:
      return "";
  }
};

export const removeProperties = (object, properties) => {
  const newObject = Object.keys(object)
    .filter((key) => !properties.includes(key))
    .reduce(
      (filtered, key) => ({
        ...filtered,
        [key]: object[key],
      }),
      {}
    );
  if (newObject.children) {
    newObject.children = newObject.children.map((c) => removeProperties(c, properties));
  }
  return newObject;
};

export const getFileExtension = (name) => (name?.match(/.*(\.[a-zA-Z]+)/i) || [])[1] || "";

export const getFileNameWithoutExtension = (name) => (name?.match(/(.*)\.[a-zA-Z]+/i) || [])[1] || "";

export const getSize = ({ size, unity = 0 }) => {
  const unityArray = ["octets", "ko", "Mo", "Go"];
  if (typeof size === "number") {
    if (size >= 1024) {
      const newSize = size / 1024;
      const newUnity = unity + 1;
      return getSize({ size: newSize, unity: newUnity });
    }
    return `${size?.toString().split(".")[0]} ${unityArray[unity]}`;
  }
  return size;
};

export const fullToolbarEditor = [
  {
    name: "paragraph",
    items: [
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "JustifyBlock",
      "-",
      "Outdent",
      "Indent",
      "-",
      "NumberedList",
      "BulletedList",
    ],
  },
  { name: "links", items: ["Link", "Unlink"] },
  { name: "insert", items: ["Image", "-", "Table", "-", "HorizontalRule"] },
  "/",
  {
    name: "basicstyles",
    items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript"],
  },
  { name: "colors", items: ["TextColor", "BGColor"] },
  { name: "styles", items: ["Format"] },
];

export const descriptionToolbarEditor = [
  {
    name: "basicstyles",
    items: ["Bold", "Italic", "Underline"],
  },
];

export const standardToolbarEditor = [
  {
    name: "basicstyles",
    items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript"],
  },
  {
    name: "paragraph",
    items: [
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "JustifyBlock",
      "-",
      "Outdent",
      "Indent",
      "-",
      "NumberedList",
      "BulletedList",
    ],
  },
  { name: "links", items: ["Link", "Unlink"] },
  { name: "styles", items: ["Format"] },
];

export const stringToSnakeCaseString = (s = "") =>
  `${unorm
    .nfd(s.toLowerCase())
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[\s']/g, "_")
    .replace(/-{2,}/g, "_")
    .replace(/-/g, "_")
    .replace(/[^a-z0-9_./~~]/g, "")
    .replace(/_$/g, "")}`;
