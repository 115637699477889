import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  step: {
    marginLeft: theme.spacing(1),
    position: "relative",
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    borderLeft: `4px solid transparent`,
    "&:not(:last-child)": {
      borderLeftColor: `${theme.palette.secondary[70]}`,
    },
    ...(theme.components.Timeline && theme.components.Timeline.step),
  },
  bubble: {
    position: "absolute",
    left: "-12px",
    top: "-1px",
    display: "block",
    height: "20px",
    width: "20px",
    backgroundColor: theme.palette.secondary[70],
    color: "white",
    borderRadius: "50%",
    ...(theme.components.Timeline && theme.components.Timeline.bubble),
  },
  title: {
    paddingBottom: theme.spacing(0.5),
    ...(theme.components.Timeline && theme.components.Timeline.title),
  },
}));

export const TimelineStep = (props) => {
  const { id, title, content } = props;
  const classes = useStyles();
  return (
    <div className={classes.step} key={id}>
      <span className={classes.bubble} />
      <div className="fr-text--bold">{title}</div>
      {content}
    </div>
  );
};

TimelineStep.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.node,
  content: PropTypes.node,
};

TimelineStep.defaultProps = {
  title: null,
  content: null,
};

const Timeline = (props) => {
  const { steps, ...others } = props;

  return <div {...others}>{steps}</div>;
};

Timeline.propTypes = {
  steps: PropTypes.node,
};

Timeline.defaultProps = {
  steps: null,
};

export default Timeline;
