import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import ScrollMenu from "react-horizontal-scrolling-menu";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "inline-block",
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      paddingRight: theme.spacing(3),
    },
  },
  arrow: {
    cursor: "pointer",
    display: "none",
    " & i": {
      fontSize: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
      " & i": {
        fontSize: "32px",
      },
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5),
  },
  arrowDisabled: {
    visibility: "hidden",
  },
}));

const HorizontalScroll = (props) => {
  const { items, itemClass, arrowClass } = props;
  const classes = useStyles();
  const { language } = useContext(LanguageContext);

  return (
    <ScrollMenu
      data={items}
      arrowLeft={
        <Button tabIndex="-1" aria-label={t[language].components.left_icon}>
          <Icon icon="long-arrow-left" iconDSFR="arrow-left-line" title={t[language].components.left_icon} />
        </Button>
      }
      arrowRight={
        <Button tabIndex="-1" aria-label={t[language].components.right_icon}>
          <Icon icon="long-arrow-right" iconDSFR="arrow-right-line" title={t[language].components.right_icon} />
        </Button>
      }
      hideArrows
      alignCenter={false}
      hideSingleArrow
      transition={1}
      translate={0.1}
      innerWrapperClass={classes.wrapper}
      itemClass={itemClass || classes.item}
      arrowClass={arrowClass || classes.arrow}
      arrowDisabledClass={classes.arrowDisabled}
      wheel={false}
      useButtonRole={false}
      inertiaScrolling
      inertiaScrollingSlowdown={0.5}
      disableTabindex
    />
  );
};
HorizontalScroll.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
  itemClass: PropTypes.string,
  arrowClass: PropTypes.string,
};
HorizontalScroll.defaultProps = {
  items: null,
  itemClass: null,
  arrowClass: null,
};
export default HorizontalScroll;
