import CookiesConsentService from "services/CookiesConsentService";

class AnalyticsService {
  static sendData = (analyticId, path) => {
    if (typeof gtag !== "undefined") {
      // eslint-disable-next-line no-undef
      gtag("config", analyticId, {
        page_path: path,
        cookie_expires: CookiesConsentService.getConsentMaxeAge(),
      });
    }
  };
}

export default AnalyticsService;
