import Geolocalisation from "components/templates/geolocalisation/Geolocalisation";
import elementsTypes from "utils/elementsTypes";
import geolocSourceType from "utils/geolocSourceType";

export default {
  key: "GEOLOCALISATION",
  label: "G - Geolocalisation",
  Component: Geolocalisation,
  initialContents: [
    {
      key: "source",
      type: elementsTypes.GEOLOC_SOURCETYPE,
      value: geolocSourceType.PHOTOGRAPHE,
    },
    {
      key: "description",
      type: elementsTypes.TEXT,
      value: "Voici la nouvelle description de la page de géolocalisation",
    },
  ],
};
