import React, { useState } from "react";
import PropTypes from "prop-types";
import ExpansionPanel from "components/templatesComponents/ExpansionPanel";

export const itemClassName = "Da-Accordion-Item";

const Accordion = (props) => {
  let { panels } = props;
  const { noPadding } = props;
  if (panels && !Array.isArray(panels)) {
    panels = [panels];
  }

  const [expanded, setExpanded] = useState(false);
  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  return (panels || []).map((panel, index) => {
    const isExpanded = index === expanded;
    return (
      <div className={itemClassName} key={panel.id}>
        <ExpansionPanel {...panel} initialExpanded={isExpanded} onChange={handleChange(index)} noPadding={noPadding} />
      </div>
    );
  });
};

Accordion.propTypes = {
  panels: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  noPadding: PropTypes.bool,
};

Accordion.defaultProps = {
  panels: [],
  noPadding: false,
};

export default Accordion;
