import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Icon from "components/templatesComponents/Icon";
import Grid from "@material-ui/core/Grid";

const PageDate = (props) => {
  const { page, position, iconColor, ...others } = props;
  const { publishedDate, updated } = page || {};

  let justify = position;
  if (position === "left") {
    justify = "flex-start";
  } else if (position === "right") {
    justify = "flex-end";
  }

  const date = publishedDate || updated;
  let formatedDate = new Date(date);
  try {
    formatedDate = formatedDate.toLocaleString("fr-FR", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  } catch (e) {
    formatedDate = null;
  }

  return (
    formatedDate && (
      <Grid {...others} container justifyContent={justify} alignItems="center">
        <Icon icon="clock" iconDSFR="time-line" style={{ color: iconColor, fontSize: "16px" }} title="Date" />
        <Box ml={1}>
          <p className="fr-text--xs" role="presentation">
            {formatedDate}
          </p>
        </Box>
      </Grid>
    )
  );
};

PageDate.propTypes = {
  page: PropTypes.shape().isRequired,
  position: PropTypes.oneOf(["left", "center", "right"]),
  iconColor: PropTypes.string,
};
PageDate.defaultProps = {
  position: "center",
  iconColor: "inherit",
};

export default PageDate;
