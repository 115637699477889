import Help from "components/templates/help/Help";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "HELP",
  label: "Aide et contact",
  Component: Help,
  servesOtherPages: true,
  initialContents: [
    {
      key: "frequentlyAskedQuestionsTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Questions fréquentes",
    },
    {
      key: "frequentlyAskedQuestions",
      type: elementsTypes.TABS,
      value: "",
      children: [
        {
          key: "identifier",
          type: elementsTypes.INPUT,
          value: "tf",
        },
      ],
    },
    {
      key: "othersQuestionsTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Une autre questions ?",
    },
    {
      key: "othersQuestions",
      type: elementsTypes.TABS_CARDS,
      value: "",
      children: [
        {
          key: "identifier",
          type: elementsTypes.INPUT,
          value: "to",
        },
      ],
    },
    {
      key: "contactTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Vous n'avez pas trouvé la réponse que vous cherchiez ?",
    },
    {
      key: "contactUsTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Contactez-nous:",
    },
    {
      key: "contactForm",
      value: "",
      children: [
        {
          key: "title",
          type: elementsTypes.EDITABLE_INPUT,
          value: "Par formulaire",
        },
        {
          key: "subTitle",
          type: elementsTypes.TEXT,
          value: "Le moyen le plus efficace pour contacter nos équipes",
        },
        {
          key: "delay",
          type: elementsTypes.TEXT,
          value: "Vous recevrez une réponse en moins de 24 heures",
        },
        {
          key: "description",
          type: elementsTypes.TEXT,
          value:
            "Afin d'avoir tous les éléments concernant votre dossier et pour mieux répondre a vos questions, nous vous conseillons de renseigner dans ce message les champs suivants",
        },
      ],
    },
    {
      key: "contactPhone",
      value: "",
      children: [
        {
          key: "title",
          type: elementsTypes.EDITABLE_INPUT,
          value: "",
        },
        {
          key: "subtitle",
          type: elementsTypes.EDITABLE_INPUT,
          value: "",
        },
        {
          key: "detail",
          value: "",
          children: [
            {
              key: "phone1",
              type: elementsTypes.TEXT,
              value: "",
            },
            {
              key: "phone2",
              type: elementsTypes.TEXT,
              value: "",
            },
            {
              key: "phone3",
              type: elementsTypes.TEXT,
              value: "",
            },
            {
              key: "deafAccess",
              type: elementsTypes.BUTTON,
              value: "",
              children: [
                {
                  key: "text",
                  value: "",
                },
                {
                  key: "link",
                  type: elementsTypes.LINK,
                  value: "",
                  children: [
                    {
                      key: "url",
                      type: elementsTypes.URL,
                      value: "",
                    },
                    {
                      key: "external",
                      type: elementsTypes.CHECKBOX,
                      value: "true",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      key: "contactDigitalPoints",
      value: "",
      children: [
        {
          key: "title",
          type: elementsTypes.EDITABLE_INPUT,
          value: "",
        },
        {
          key: "subtitle",
          type: elementsTypes.EDITABLE_INPUT,
          value: "",
        },
        {
          key: "detail",
          value: "",
          children: [
            {
              key: "text",
              type: elementsTypes.TEXT,
              value: "",
            },
            {
              key: "digitalPoint",
              type: elementsTypes.BUTTON,
              value: "",
              children: [
                {
                  key: "text",
                  value: "",
                },
                {
                  key: "link",
                  type: elementsTypes.LINK,
                  value: "",
                  children: [
                    {
                      key: "url",
                      type: elementsTypes.URL,
                      value: "",
                    },
                    {
                      key: "external",
                      type: elementsTypes.CHECKBOX,
                      value: "true",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
