import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import DSFRModal from "components/templatesComponents/DSFRModal";
import CookiesTable from "components/CookiesTable";
import Link from "components/templatesComponents/Link";
import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import CookiesModalContext from "components/CookiesModalContext";
import { getSiteName, getTranslateIndex } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  description: {
    paddingBottom: theme.spacing(1.5),
    maxWidth: "820px",
  },
  externalIcon: {
    paddingLeft: "6px",
    "&::before": {
      "--icon-size": "0.875rem",
    },
  },
  externalLink: {
    paddingBottom: theme.spacing(2),
    backgroundImage: "none",
  },
  radio: {
    "&.Mui-focusVisible": {
      border: "2px solid #0a76f6",
    },
  },
}));

const CookiesModal = (props) => {
  const { cookieConsent, handleCookiesModal, ...others } = props;

  const [eulerianConsent, setEulerianConsent] = useState("Accepter");

  const { openCookiesModal } = useContext(CookiesModalContext);
  const { language } = useContext(LanguageContext);
  const site = useContext(SiteContext);
  const { name: siteName } = site;

  const classes = useStyles();

  useEffect(() => {
    if (["false", false].includes(cookieConsent)) {
      setEulerianConsent("Refuser");
    } else {
      setEulerianConsent("Accepter");
    }
  }, [cookieConsent]);

  const createData = (name, finality, conservation) => {
    return { name, finality, conservation };
  };

  const requiredCookiesRows = [
    createData("Local Storage", t[language].components.storage_description),
    createData("Session", t[language].components.session_description),
  ];

  const eulerianCookiesRows = [
    createData(
      "AT-Internet",
      `${t[language].components.use_cookie[getTranslateIndex(3)]} (atauthority, atidvisitor, atuserid)`
    ),
    createData("Eulerian", `${t[language].components.use_cookie[getTranslateIndex(3)]} (etuix)`),
  ];

  return (
    <DSFRModal
      open={openCookiesModal}
      aria-labelledby="admin-content"
      aria-describedby="content-administration"
      onClose={handleCookiesModal}
      size={{ md: 10, lg: 8 }}
    >
      <div className="fr-modal__content">
        <h1 id="fr-modal-title-modal-cookies" className="fr-modal__title">
          {t[language].components.title}
        </h1>
        <p className={classes.description} role="presentation">
          {t[language].components.description1}
        </p>
        <p className={classes.description} role="presentation">
          {t[language].components.description2}
        </p>
        <CookiesTable
          cookiesRows={requiredCookiesRows}
          disabled
          subtitle={t[language].components.required_cookies_title}
          description={
            <p className={classes.description} role="presentation">
              {t[language].components.required_cookies_description}
            </p>
          }
          {...others}
        />
        <div style={{ height: "24px" }} />
        <CookiesTable
          cookiesRows={eulerianCookiesRows}
          consent={eulerianConsent}
          setConsent={setEulerianConsent}
          description={
            <p className={classes.description} role="presentation">
              {`${t[language].components.tracker_cookies_description} "${getSiteName(siteName)}".`}
            </p>
          }
          subtitle={t[language].components.tracker_cookies_title}
          classes={classes}
          {...others}
        />
        <Link
          linkComponent
          external
          url="https://www.atinternet.com/societe/rgpd-et-vie-privee/collecte-de-donnees-sur-les-sites-de-nos-clients/"
          className={classnames(classes.externalLink, "fr-text--bold")}
        >
          {t[language].components.tracker_link}
        </Link>
        <p className={classes.description} role="presentation">
          {t[language].components.description3}
        </p>
      </div>
    </DSFRModal>
  );
};

CookiesModal.propTypes = {
  cookieConsent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  handleCookiesModal: PropTypes.func.isRequired,
};

CookiesModal.defaultProps = {
  cookieConsent: null,
};

export default CookiesModal;
