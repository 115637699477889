import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const GeolocMobileTabs = (props) => {
  const { classes, activeTab, setActiveTab } = props;

  const { language } = useContext(LanguageContext);

  const tabArray = [
    { id: "1", label: t[language].geolocalisation.view_map },
    { id: "2", label: t[language].geolocalisation.view_list },
  ];
  return (
    <Grid container className={classes.hiddenDesktop}>
      {tabArray.map((tab) => (
        <Grid item xs={6} key={tab.id} className={classes.tabMap}>
          <div
            role="button"
            onClick={() => setActiveTab(tab.id)}
            className={activeTab === tab.id ? classes.active : ""}
          >
            {tab.label}
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

GeolocMobileTabs.propTypes = {
  classes: PropTypes.shape().isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default GeolocMobileTabs;
