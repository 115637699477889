import React, { useEffect, useState, useContext } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles(() => ({
  captcha: {
    paddingLeft: 12,
    paddingRight: 12,
    "& .visualCaptcha": {
      marginBottom: 8,
    },
    "& .visualCaptcha-explanation": {
      fontFamily: "'Marianne', sans-serif",
    },
    "& .status": {
      color: "#f44336",
    },
    "& .img > a": {
      display: "inline-block",
    },
  },
}));

const Captcha = (props) => {
  const { id, captchaMessage, className } = props;

  const classes = useStyles();

  const [display, setDisplay] = useState();

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (language) {
      setDisplay(false);
      setTimeout(() => {
        setDisplay(true);
      }, 500);
    }
  }, [language]);

  if (!display || !process.env.visualcaptcha_secret_key) return null;

  return (
    <div key={id} className={classnames("pre-captcha-wrapper", classes.captcha, className)}>
      <div aria-label={t[language].components.captcha_message} />
      <div className="captcha-wrapper">
        <div id={id} />
        {captchaMessage && <FormHelperText className="status">{captchaMessage}</FormHelperText>}
      </div>
      <Helmet>
        <script src="/visualcaptcha/visualcaptcha.main.js" />
      </Helmet>
    </div>
  );
};

Captcha.propTypes = {
  id: PropTypes.string.isRequired,
  captchaMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Captcha.defaultProps = {
  className: "",
};

export default Captcha;
