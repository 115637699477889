import FloatingImage from "components/templates/floatingImage/FloatingImage";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "FLOATING",
  label: "U - Image flottante",
  Component: FloatingImage,
  initialContents: [
    {
      key: "dynamicPart",
      type: elementsTypes.DYNAMIC,
      value: "",
      children: [],
    },
  ],
};
